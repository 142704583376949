<div>
  <div [ngClass]="bgClass">
    <app-header></app-header>
  </div>
</div>
<div >
  <ngx-spinner size = "default" color = "#fff" type = "square-jelly-box" [fullScreen] = "true"><p style="color: white" > Loading... </p></ngx-spinner>
  <router-outlet></router-outlet>
</div>
<div>
  <app-footer></app-footer>
</div>
