import {NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HeaderComponent } from './modules/shared/header/header/header.component';
import { SignupComponent } from './modules/signup/signup.component';
import { AuthGuardService } from './services/auth-guard.service';


const routes: Routes = [
  { path: 'about',loadChildren: () => import('./about/about.module').then(m => m.AboutModule)  , runGuardsAndResolvers: 'always' },
  { path: 'post-property', loadChildren: () => import('./post-property/post-property.module').then(m => m.PostPropertyModule) },
  { path: 'search-property', loadChildren: () => import('./search-property/search-property.module').then(m => m.SearchPropertyModule) },
  { path: 'build-to-suit-warehouse', loadChildren: () => import('./search-property/search-property.module').then(m => m.SearchPropertyModule), data: { some_data: 'some value' } },
  { path: 'ready-to-move-warehouse', loadChildren: () => import('./search-property/search-property.module').then(m => m.SearchPropertyModule) },
  { path: 'latest-property', loadChildren: () => import('./search-property/search-property.module').then(m => m.SearchPropertyModule) },
  { path: 'residential-properties', loadChildren: () => import('./search-property/search-property.module').then(m => m.SearchPropertyModule) },
  { path: 'commercial-properties', loadChildren: () => import('./search-property/search-property.module').then(m => m.SearchPropertyModule) },
  { path: 'view-enquiry', loadChildren: () => import('./view-inquiry/view-inquiry.module').then(m => m.ViewInquiryModule) },
  // { path: 'view-property-contact-info', loadChildren: () => import('./view-property-contact-info/view-property-contact-info.module').then(m => m.ViewPropertyContactInfoModule) },
  { path: 'privacy-policy', loadChildren: () => import('./privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyModule) },
  // { path: 'terms-and-condition', component: TermsAndConditionComponent },
  { path: 'view-property', loadChildren: () => import('./search-property-details/search-property-details.module').then(m => m.SearchPropertyDetailsModule) },
  {
    path: 'view-property', children: [
      {
        path: '**',
        loadChildren: () => import('./search-property-details/search-property-details.module').then(m => m.SearchPropertyDetailsModule) 
      }
    ]
  },
  {
    path: 'view-warehouse', children: [
      {
        path: '**',
        loadChildren: () => import('./search-property-details/search-property-details.module').then(m => m.SearchPropertyDetailsModule) 
      }
    ]
  },
  {
    path: 'view-warehouse.php', children: [
      {
        path: '**',
        loadChildren: () => import('./search-property-details/search-property-details.module').then(m => m.SearchPropertyDetailsModule) 
      }
    ]
  },
  { path: 'contact-us', loadChildren: () => import('./contact-us/contact-us.module').then(m => m.ContactUsModule), runGuardsAndResolvers: 'always' },
  { path: 'sign-up',component:SignupComponent },
  {
    path: 'user', //user or agent
    children: [
      { path: 'dashboard', loadChildren: () => import('./personal-dashboard/personal-dashboard.module').then(m => m.PersonalDashboardModule), canActivate: [AuthGuardService] },
      { path: 'view-profile', loadChildren: () => import('./view-profile/view-profile.module').then(m => m.ViewProfileModule), canActivate: [AuthGuardService] },
      { path: 'edit-profile', loadChildren: () => import('./edit-profile/edit-profile.module').then(m => m.EditProfileModule), canActivate: [AuthGuardService] },
      { path: 'change-password',  loadChildren: () => import('./change-password/change-password.module').then(m => m.ChangePasswordModule), canActivate: [AuthGuardService] },
      { path: 'list-property', loadChildren: () => import('./list-property/list-property.module').then(m => m.ListPropertyModule), canActivate: [AuthGuardService] },
      { path: 'buy-warehouse-listing', loadChildren: () => import('./buy-warehouse-listing/buy-warehouse-listing.module').then(m => m.BuyWarehouseListingModule) },
      { path: 'view-enquiry', loadChildren: () => import('./view-inquiry/view-inquiry.module').then(m => m.ViewInquiryModule) },
      { path: 'view-property-contact-info', loadChildren: () => import('./view-property-contact-info/view-property-contact-info.module').then(m => m.ViewPropertyContactInfoModule) },
      {
        path: '**',
        loadChildren: () => import('./personal-dashboard/personal-dashboard.module').then(m => m.PersonalDashboardModule)
      }
    ]
  },
  {
    path: 'employee', // employee or manager
    children: [
      { path: 'dashboard', loadChildren: () => import('./employee-dashboard/employee-dashboard.module').then(m => m.EmployeeDashboardModule), canActivate: [AuthGuardService] },
      { path: 'view-profile',  loadChildren: () => import('./view-profile/view-profile.module').then(m => m.ViewProfileModule), canActivate: [AuthGuardService] },
      { path: 'edit-profile', loadChildren: () => import('./edit-profile/edit-profile.module').then(m => m.EditProfileModule), canActivate: [AuthGuardService] },
      { path: 'create-new-page', loadChildren: () => import('./createnewpage/createnewpage.module').then(m => m.CreatenewpageModule), canActivate: [AuthGuardService] },
      { path: 'view-dynamic-page', loadChildren: () => import('./view-dynamic-page/view-dynamic-page.module').then(m => m.ViewDynamicPageModule), canActivate: [AuthGuardService] },
      { path: 'change-password', loadChildren: () => import('./change-password/change-password.module').then(m => m.ChangePasswordModule), canActivate: [AuthGuardService] },
      { path: 'view-contact-us', loadChildren: () => import('./view-contact-us/view-contact-us.module').then(m => m.ViewContactUsModule), canActivate: [AuthGuardService] },
      { path: 'list-property', loadChildren: () => import('./list-property/list-property.module').then(m => m.ListPropertyModule), canActivate: [AuthGuardService] },
      { path: 'view-posted-property', loadChildren: () => import('./view-posted-property/view-posted-property.module').then(m => m.ViewPostedPropertyModule), canActivate: [AuthGuardService] },
      { path: 'view-enquiry', loadChildren: () => import('./view-inquiry/view-inquiry.module').then(m => m.ViewInquiryModule) },
      { path: 'view-property-contact-info', loadChildren: () => import('./view-property-contact-info/view-property-contact-info.module').then(m => m.ViewPropertyContactInfoModule) },
      { path: 'city-master', loadChildren: () => import('./city-master/city-master.module').then(m => m.CityMasterModule) },
      { path: 'city-locality-master', loadChildren: () => import('./city-locality-master/city-locality-master.module').then(m => m.CityLocalityMasterModule) },
      { path: 'create-project-gallery', loadChildren: () => import('./project-gallery/project-gallery.module').then(m => m.ProjectGalleryModule) },
      { path: 'view-project-gallery', loadChildren: () => import('./view-project-gallery/view-project-gallery.module').then(m => m.ViewProjectGalleryModule) },
      // { path: 'project-gallery-list', loadChildren: () => import('./project-gallery-list/project-gallery-list.module').then(m => m.ProjectGalleryListModule)  },
      { path: 'project-gallery-detail', 
      loadChildren: () => import('./project-gallary-details/project-gallary-details.module').then(m => m.ProjectGallaryDetailsModule)
    },
      {
        path: '**',
        loadChildren: () => import('./admin-dashboard/admin-dashboard.module').then(m => m.AdminDashboardModule)
      }
    ]
  },
  {
    path: 'admin',
    canActivate: [AuthGuardService],
    children: [
      { path: 'dashboard',  loadChildren: () => import('./admin-dashboard/admin-dashboard.module').then(m => m.AdminDashboardModule), canActivate: [AuthGuardService] },
      { path: 'view-employee', loadChildren: () => import('./view-employee/view-employee.module').then(m => m.ViewEmployeeModule), canActivate: [AuthGuardService] },
      { path: 'view-profile',  loadChildren: () => import('./view-profile/view-profile.module').then(m => m.ViewProfileModule), canActivate: [AuthGuardService] },
      { path: 'view-dynamic-page', loadChildren: () => import('./view-dynamic-page/view-dynamic-page.module').then(m => m.ViewDynamicPageModule), canActivate: [AuthGuardService] },
      { path: 'view-contact-us', loadChildren: () => import('./view-contact-us/view-contact-us.module').then(m => m.ViewContactUsModule), canActivate: [AuthGuardService] },
      { path: 'edit-profile', loadChildren: () => import('./edit-profile/edit-profile.module').then(m => m.EditProfileModule), canActivate: [AuthGuardService] },
      { path: 'register-employee', loadChildren: () => import('./register-employee/register-employee.module').then(m => m.RegisterEmployeeModule), canActivate: [AuthGuardService] },
      { path: 'create-new-page', loadChildren: () => import('./createnewpage/createnewpage.module').then(m => m.CreatenewpageModule), canActivate: [AuthGuardService] },
      { path: 'change-password', loadChildren: () => import('./change-password/change-password.module').then(m => m.ChangePasswordModule), canActivate: [AuthGuardService] },
      { path: 'view-posted-property', loadChildren: () => import('./view-posted-property/view-posted-property.module').then(m => m.ViewPostedPropertyModule), canActivate: [AuthGuardService] },
      { path: 'view-warehouse-payment', loadChildren: () => import('./view-warehouse-payment/view-warehouse-payment.module').then(m => m.ViewWarehousePaymentModule), canActivate: [AuthGuardService] },
      { path: 'view-listing-payment', loadChildren: () => import('./view-listing-payment/view-listing-payment.module').then(m => m.ViewListingPaymentModule), canActivate: [AuthGuardService] },
      { path: 'view-enquiry', loadChildren: () => import('./view-inquiry/view-inquiry.module').then(m => m.ViewInquiryModule) },
      { path: 'view-property-contact-info', loadChildren: () => import('./view-property-contact-info/view-property-contact-info.module').then(m => m.ViewPropertyContactInfoModule) },
      { path: 'city-master', loadChildren: () => import('./city-master/city-master.module').then(m => m.CityMasterModule) },
      { path: 'city-locality-master', loadChildren: () => import('./city-locality-master/city-locality-master.module').then(m => m.CityLocalityMasterModule) },
      { path: 'create-project-gallery', loadChildren: () => import('./project-gallery/project-gallery.module').then(m => m.ProjectGalleryModule) },
      { path: 'view-project-gallery', loadChildren: () => import('./view-project-gallery/view-project-gallery.module').then(m => m.ViewProjectGalleryModule) },
      // { path: 'project-gallery-list', loadChildren: () => import('./project-gallery-list/project-gallery-list.module').then(m => m.ProjectGalleryListModule)  },
      
      { path: 'project-gallery-detail', loadChildren: () => import('./project-gallary-details/project-gallary-details.module').then(m => m.ProjectGallaryDetailsModule) },
      {
        path: '**',
        loadChildren: () => import('./admin-dashboard/admin-dashboard.module').then(m => m.AdminDashboardModule)
      }
    ]
  },
  {
    path: 'property',
    children: [
      {
        path: '',
        loadChildren: () => import('./apartment-for-rent-city/apartment-for-rent-city.module').then(m => m.ApartmentForRentCityModule)
  
      }
    ]
  },
  {
    path: 'warehouse-for-rent-in/:id',
    children: [
      {
        path: '**',
        loadChildren: () => import('./warehouse-for-rent-city/warehouse-for-rent-city.module').then(m => m.WarehouseForRentCityModule)
      }
    ]
  },
  { path: 'warehouse-for-rent-in-gurgoan',  loadChildren: () => import('./warehouse-for-rent-city/warehouse-for-rent-city.module').then(m => m.WarehouseForRentCityModule)},
  { path: 'warehouse-for-rent-in-lucknow',  loadChildren: () => import('./warehouse-for-rent-city/warehouse-for-rent-city.module').then(m => m.WarehouseForRentCityModule) },
  { path: 'warehouse-for-rent-in-gorakhpur',  loadChildren: () => import('./warehouse-for-rent-city/warehouse-for-rent-city.module').then(m => m.WarehouseForRentCityModule) },
  { path: 'warehouse-for-rent-in-bhiwandi',  loadChildren: () => import('./warehouse-for-rent-city/warehouse-for-rent-city.module').then(m => m.WarehouseForRentCityModule) },
  { path: 'warehouse-for-rent-in-greater-noida',  loadChildren: () => import('./warehouse-for-rent-city/warehouse-for-rent-city.module').then(m => m.WarehouseForRentCityModule) },
  { path: 'warehouse-for-rent-in-bangalore',  loadChildren: () => import('./warehouse-for-rent-city/warehouse-for-rent-city.module').then(m => m.WarehouseForRentCityModule) },
  { path: 'warehouse-for-rent-in-greater-bangalore',  loadChildren: () => import('./warehouse-for-rent-city/warehouse-for-rent-city.module').then(m => m.WarehouseForRentCityModule) },
  { path: 'warehouse-for-rent-in-varanasi',  loadChildren: () => import('./warehouse-for-rent-city/warehouse-for-rent-city.module').then(m => m.WarehouseForRentCityModule) },
  { path: 'godown-for-rent-navi-mumbai',  loadChildren: () => import('./warehouse-for-rent-city/warehouse-for-rent-city.module').then(m => m.WarehouseForRentCityModule) },
  { path: 'warehouse-for-rent-in-hyderabad',  loadChildren: () => import('./warehouse-for-rent-city/warehouse-for-rent-city.module').then(m => m.WarehouseForRentCityModule) },
  { path: 'warehouse-for-rent-in-chennai',  loadChildren: () => import('./warehouse-for-rent-city/warehouse-for-rent-city.module').then(m => m.WarehouseForRentCityModule) },
  { path: 'warehouse-for-rent-in-kolkata',  loadChildren: () => import('./warehouse-for-rent-city/warehouse-for-rent-city.module').then(m => m.WarehouseForRentCityModule) },
  { path: 'godown-for-rent-bhubaneswar',  loadChildren: () => import('./warehouse-for-rent-city/warehouse-for-rent-city.module').then(m => m.WarehouseForRentCityModule) },
  { path: 'godown-for-rent-in-coimbatore',  loadChildren: () => import('./warehouse-for-rent-city/warehouse-for-rent-city.module').then(m => m.WarehouseForRentCityModule) },
  { path: 'godown-for-rent-in-indore',  loadChildren: () => import('./warehouse-for-rent-city/warehouse-for-rent-city.module').then(m => m.WarehouseForRentCityModule) },
  { path: 'warehouse-for-rent-in-mysore',  loadChildren: () => import('./warehouse-for-rent-city/warehouse-for-rent-city.module').then(m => m.WarehouseForRentCityModule) },
  { path: 'warehouse-for-rent-in-noida',  loadChildren: () => import('./warehouse-for-rent-city/warehouse-for-rent-city.module').then(m => m.WarehouseForRentCityModule) },
  { path: 'warehouse-for-rent-in-digha-patna',  loadChildren: () => import('./warehouse-for-rent-city/warehouse-for-rent-city.module').then(m => m.WarehouseForRentCityModule) },
  { path: 'warehouse-for-rent-in-transport-nagar-lucknow', loadChildren: () => import('./warehouse-for-rent-city/warehouse-for-rent-city.module').then(m => m.WarehouseForRentCityModule) },
  { path: 'warehouse-for-rent-in-kanpur',  loadChildren: () => import('./warehouse-for-rent-city/warehouse-for-rent-city.module').then(m => m.WarehouseForRentCityModule) },
  { path: 'godown-for-rent-in-jaipur',  loadChildren: () => import('./warehouse-for-rent-city/warehouse-for-rent-city.module').then(m => m.WarehouseForRentCityModule) },
  { path: 'godown-for-rent-in-pune',  loadChildren: () => import('./warehouse-for-rent-city/warehouse-for-rent-city.module').then(m => m.WarehouseForRentCityModule) },
  { path: 'godown-for-rent-in-chandigarh',  loadChildren: () => import('./warehouse-for-rent-city/warehouse-for-rent-city.module').then(m => m.WarehouseForRentCityModule) },
  { path: 'godown-for-rent-in-ahmedabad',  loadChildren: () => import('./warehouse-for-rent-city/warehouse-for-rent-city.module').then(m => m.WarehouseForRentCityModule) },
  { path: 'godown-for-rent-in-nagpur',  loadChildren: () => import('./warehouse-for-rent-city/warehouse-for-rent-city.module').then(m => m.WarehouseForRentCityModule) },
  // { path: 'view-employee',  loadChildren: () => import('./view-employee/view-employee.module').then(m => m.ViewEmployeeModule) },
  { path: 'login', component: HeaderComponent, redirectTo: '' },
  { path: 'unauthorized', component: HeaderComponent, redirectTo: '' },
  { path: 'buy-warehouse-listing', loadChildren: () => import('./buy-warehouse-listing/buy-warehouse-listing.module').then(m => m.BuyWarehouseListingModule) },
  { path: 'listing-payment', loadChildren: () => import('./payment/payment.module').then(m => m.PaymentModule) },
  { path: 'logout', loadChildren: () => import('./logout/logout.module').then(m => m.LogoutModule) },
  { path: 'home', loadChildren: () => import('./home/home.module').then(m => m.HomeModule)  , pathMatch: 'full' },
  { path: 'pagenotfound', loadChildren: () => import('./page-not-found/page-not-found.module').then(m => m.PageNotFoundModule)   },
  {
    path: '',
    children: [
      { path: '', loadChildren: () => import('./home/home.module').then(m => m.HomeModule)   },
      { path: 'property', loadChildren: () => import('./apartment-for-rent-city/apartment-for-rent-city.module').then(m => m. ApartmentForRentCityModule) }
    ]
  },
  {
    path: '**',
     loadChildren: () => import('./page-not-found/page-not-found.module').then(m => m.PageNotFoundModule) }

];




@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false, onSameUrlNavigation: 'reload', initialNavigation: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { 

}
