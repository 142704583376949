import { MatConfirmDialogComponent } from '../modules/mat-confirm-dialog/mat-confirm-dialog.component'
import { Injectable } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { Output, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})


export class DialogService {

  @Output() public onChangePostProperty: EventEmitter<any> = new EventEmitter();

  @Output() public onSubmitEnquiry: EventEmitter<any> = new EventEmitter();

  constructor(private dialog: MatDialog, private dialog2: MatDialog) { }

  openConfirmDialog(msg, isFocusOnYesBtn=false, yesBtnText='YES', noBtnText='NO') {
    return this.dialog.open(MatConfirmDialogComponent, {
      width: '430px',
      panelClass: 'confirm-dialog-container',
      disableClose: true,
      autoFocus: isFocusOnYesBtn,
      data: {
        message: msg,
        yesButtonText: yesBtnText,
        noButtonText: noBtnText,
        isFocusOnYesButton: isFocusOnYesBtn
      }
    });
  }
  //Do not use this method for dialog, Only meant for Session Continuation Confirmation Box.
  openConfirmDialog2(msg, isFocusOnYesBtn=false, yesBtnText='YES', noBtnText='NO') {
    return this.dialog2.open(MatConfirmDialogComponent, {
      width: '430px',
      panelClass: 'confirm-dialog-container',
      disableClose: true,
      autoFocus: isFocusOnYesBtn,
      data: {
        message: msg,
        yesButtonText: yesBtnText,
        noButtonText: noBtnText,
        isFocusOnYesButton: isFocusOnYesBtn
      }
    });
  }
  closeAllDialog()
  {
    this.dialog.closeAll();
  }

}


