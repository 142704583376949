import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class CityDataService {
  cityData = new Subject();
  private data = [];
  private searchData = [];

 setOption(value) {
    this.data = value;
  }

  getOption() {
    return this.data;
  }

  setSearchData(data) {
     this.searchData = data;
  }

  getSearchData() {
    return this.searchData;
  }

  setCityData(cityData:any) {
     this.cityData.next(cityData);
  }
}

export class PropertyListByTypeDataService {

  private data = [];

 setOption(value) {
    this.data = value;
  }

  getOption() {
    return this.data;
  }
}


export class PropertyUnitsDS{
  private data = [];

  setOption(value) {
     this.data = value;
   }

   getOption() {
     return this.data;
   }
}
