import { PropertyTypeUrlConstants, PropertyListByTypeUrlConstants, PropertyUnitUrlConstants, PropertyAmenitiesByTypeUrlConstants, CityUrlConstants } from '../common/Url.Constant';
import { Injectable } from '@angular/core';
import { RequestMethodRepository } from '../common/RequestMethods.repository';
import { OperationInfo } from '../model/common.model';
import { CityMasterModel } from '../model/cityMaster.model';


@Injectable()
export class PropertyMasterService {

  constructor(private httpRepository: RequestMethodRepository) {

  }

  getAllPropertyType(operationInfo: OperationInfo) {
    return this.httpRepository.GetAll(PropertyTypeUrlConstants.getPropertyType, operationInfo);
  }

  getAllPropertyListByType(operationInfo: OperationInfo) {
    return this.httpRepository.GetAll(PropertyListByTypeUrlConstants.getPropertyListByType, operationInfo);
  }

  getAllPropertyUnits(operationInfo: OperationInfo) {
    return this.httpRepository.GetAll(PropertyUnitUrlConstants.getPropertyUnits, operationInfo);
  }

  getAllPropertyAmenitiesByType(operationInfo: OperationInfo) {
    return this.httpRepository.GetAll(PropertyAmenitiesByTypeUrlConstants.getPropertyAmenitiesByType, operationInfo);
  }

  getAllState(operationInfo: OperationInfo) {
    return this.httpRepository.GetAll(CityUrlConstants.getState, operationInfo);
  }

  getCityByState(id: number) {
    return this.httpRepository.Get(CityUrlConstants.getCityByState.replace("{id}", id.toString()));
  }

  getAllCity(operationInfo: OperationInfo) {
    return this.httpRepository.GetAll(CityUrlConstants.getCity, operationInfo);
  }

  getCityById(id: number) {
    return this.httpRepository.Get(CityUrlConstants.getCityById.replace("{id}", id.toString()));
  }

  addNewCity(cityMasterModel: CityMasterModel) {
    return this.httpRepository.Post(CityUrlConstants.getCity, cityMasterModel);
  }

  deleteCity(id: number) {
    return this.httpRepository.Delete(CityUrlConstants.getCityById.replace("{id}", id.toString()));
  }
}
