import { Component } from '@angular/core';
import * as $ from 'jquery';
import { NavigationEnd, Router, ActivatedRoute } from '@angular/router';
import { Options, LabelType } from 'ng5-slider';
import { Title, Meta } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { CommonFunctions } from './common/CommonFunction';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent {
  title = 'warehousewale';
  bgClass = 'default';
  currentUrl: any;
  param: any;
  that: any;
  userType: number;

  minValue: number = 100;
  maxValue: number = 400;
  options: Options = {
    floor: 0,
    ceil: 500,
    translate: (value: number, label: LabelType): string => {
      switch (label) {
        case LabelType.Low:
          return '<b>Min price:</b> $' + value;
        case LabelType.High:
          return '<b>Max price:</b> $' + value;
        default:
          return '$' + value;
      }
    }
  };

  constructor(
    private router: Router, private _title: Title, private _meta: Meta, public _commonFunctions: CommonFunctions, private activatedRoute: ActivatedRoute
  ) {

    var url = window.location.pathname; //+ decodeURIComponent(location.search);
    this.currentUrl = url;
    // subscribe to router navigation
    this.router.events.subscribe(event => {
      // filter `NavigationEnd` events
      if (event instanceof NavigationEnd) {
        // get current route without leading slash `/`
        const eventUrl = event.urlAfterRedirects; ///(?<=\/).+/.exec(event.urlAfterRedirects);
        const canonical:any = document.querySelector('link[rel="canonical"]');
        if (canonical !== null) {
          canonical.href = "https://www.warehousewale.com"+eventUrl;
        }
        //const currentRoute = (eventUrl || []).join('');
        // set bgClass property with the value of the current route
        if (eventUrl === "/" || eventUrl === null)
          this.bgClass = 'main-page'; //this.bgClass = currentRoute;
        else
          this.bgClass = 'child-page';

        this._title.setTitle("Rent-Sale-Buy Properties - Warehouse for Rent - Residential Property - Commercial Property");
        this._meta.updateTag({ name: 'description', content: "Rent-Sale-Buy Properties in India – BTS warehouse in India, Explore the Best verified Residential and commercial properties in your area on Warehousewale.com, Find the best Property in India's from most Famous Real Estate Portal best owner-broker properties" });
        this._meta.updateTag({ name: 'keyword', content: 'Real Estate in India, Property in India, Indian Real Estate, Properties In India, Property For Sale, Warehouse for rent in India, Property For Rent, Buy Property In India, Commercial Property in India, Property for Sell, Residential Property in India, Commercial Properties for Sale ' });

      }
    });

    this.activatedRoute.queryParams.subscribe(params => {
      if (params['city'] != undefined) {
        this._commonFunctions.queryParameters = params;
      }
    });
  }

  ngOnInit() {
   
    this.userType = Number(localStorage.getItem('userType'))
    if (isNaN(this.userType))
      this.userType = 0;

    // if (this.currentUrl != "/")
    //   this.redirectTo(this.currentUrl);
    // if (this.userType == 1 && this.currentUrl == "/") {
    //   this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
    //     this.router.navigate(['/admin']));
    // } if ((this.userType == 2 || this.userType == 3) && this.currentUrl == "/") {
    //   this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
    //     this.router.navigate(['/employee']));
    // } if ((this.userType == 4 || this.userType == 5) && this.currentUrl == "/") {
    //   this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
    //     this.router.navigate(['/user']));
    // }
    this.deleteAllCookies();
  }
  // Custom refresh code
  redirectTo(uri: string) {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate([uri], { queryParams: this._commonFunctions.queryParameters }));
  }

  deleteAllCookies() {
    var cookies = document.cookie.split(";");

    for (var i = 0; i < cookies.length; i++) {
      var cookie = cookies[i];
      var eqPos = cookie.indexOf("=");
      var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
  }
}
