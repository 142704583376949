import { Injectable } from '@angular/core';
import { RequestMethodRepository } from '../common/RequestMethods.repository';
import { CityLocalityUrlConstants } from '../common/Url.Constant';
import { OperationInfo } from '../model/common.model';
import { CitiesLocalityModel } from '../model/cityMaster.model';

@Injectable()
export class CityLocalityService {

  constructor(private httpRepository: RequestMethodRepository) {
  }

  getCitiesLocality(operationInfo: OperationInfo) {
    return this.httpRepository.GetAll(CityLocalityUrlConstants.getCityLocality, operationInfo);
  }

  getCitiesLocalityById(citiesLocalityId: string) {
    return this.httpRepository.Get(CityLocalityUrlConstants.getCityLocalityById.replace("{id}", citiesLocalityId.toString()));
  }

  postCitiesLocality(citiesLocality: CitiesLocalityModel) {
    return this.httpRepository.Post(CityLocalityUrlConstants.postCityLocality, citiesLocality);
  }

  deleteCitiesLocalityIdId(citiesLocalityId: string) {
    return this.httpRepository.Delete(CityLocalityUrlConstants.deleteCityLocalityById.replace("{id}", citiesLocalityId.toString()));
  }


}
