import { Component, OnInit,Inject } from '@angular/core';
import { Router } from '@angular/router';
import { DynamicPageContentService } from '../../../../services/dynamic-page-content.service';
import { PropertyMasterService } from '../../../../services/property-master.service';
import { SearchConditionalOperators, PropertySearchModel, SearchOperators, OperationInfo, OperationTypes, SortingTypes, PagingButtonUrl, PagingButton } from '../../../../model/common.model';
import { CommonFunctions } from '../../../../common/CommonFunction';
import { ToastrService } from 'ngx-toastr';
import { DynamicPageContent, DynamicPageContentHeading } from '../../../../model/pageContent.model';
import { KindOfPropertyService } from '../../../../services/kind-of-property.service';
import { AnonymousSubject } from 'rxjs/internal/Subject';
import { MatTableDataSource } from '@angular/material/table';
import { DialogService } from '../../../../services/dialog.service';
import { environment } from 'src/environments/environment';
import { CityDataService } from '../../../../services/shared-data.service';
import { isPlatformServer } from '@angular/common';
import { PLATFORM_ID} from '@angular/core';


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.less']
})
export class FooterComponent implements OnInit {
  isServer = isPlatformServer(this.platformId);
  isViewOnly: boolean = false;
  searchConditionalOperators: any;
  searchOperators: any;
  operationTypes: any;
  sortingTypes: any;
  recordCount: number = 0;
  operationInfo: OperationInfo;
  pagingButtonUrl: PagingButtonUrl;
  pagingButton: any;
  propertyListByType: any;
  dynamicPageContent: DynamicPageContent;
  dynamicPageContentHeading: DynamicPageContentHeading;
  dynamicPageContentHeadingList: DynamicPageContentHeading[];
  dynamicPageRecords: any;
  searchByCity: string;
  citydropdown: boolean = false;
  constructor(@Inject(PLATFORM_ID) private platformId: Object,private _router: Router, private _cityDataService: CityDataService, private _dialogService: DialogService, protected _toaster: ToastrService, protected _kindOfPropertyService: KindOfPropertyService, protected _commonFunctions: CommonFunctions, protected _dynamicPageContentService: DynamicPageContentService, protected _propertyMasterService: PropertyMasterService) { }

  ngOnInit() {
    this.operationTypes = this._commonFunctions.getParsedEnum(OperationTypes);
    this.sortingTypes = this._commonFunctions.getParsedEnum(SortingTypes);
    this.searchConditionalOperators = this._commonFunctions.getParsedEnum(SearchConditionalOperators);
    this.searchOperators = this._commonFunctions.getParsedEnum(SearchOperators);
    this.initializeOperationInfo();
    //await this.getPropertyListByType();
    //await this.getAllKindOfProperty();
    this.getCity();
    //this.onChangeResidentialOrCommercial(1);
    this.searchByCity = "Lucknow";
    this.onChangeGetPage();
  }

  navigateTo(url) {
    this._router.navigateByUrl(url);
  };

  initializeOperationInfo() {
    this.dynamicPageContent = new DynamicPageContent();
    this.dynamicPageContentHeading = new DynamicPageContentHeading();
    this.dynamicPageContentHeadingList = [];

    this.operationInfo = new OperationInfo();
    this.operationInfo.pageIndex = 1;
    this.operationInfo.pageSize = 100;
    this.operationInfo.provideListResource = true;
    this.operationInfo.sortingColumn = "Id";
    this.operationInfo.sortingType = this.sortingTypes[SortingTypes.Asc];

    this.dynamicPageContent.propertyTypeId = 1;
    this.dynamicPageContent.propertySubTypeId = 0;
    this.dynamicPageContent.kindOfPropertyId = 0;
  }

  async getDynamicPageRecords() {

    //ristrict round trip
    await this._dynamicPageContentService.get(this.operationInfo).toPromise().then(
      data => {
        if (data != null) {
          this.recordCount = data.recordCount;
          this.dynamicPageRecords = data.resources;
          //this.pagingButtonUrl = this._commonFunctions.initializePagingButtonURL(this.operationInfo, this.recordCount);
        }
      },
      error => {
        this._commonFunctions.showError(this._toaster, error);
      }
    );

  }

  navigateToDynamicPage(propertySubTypeName, cityName, propertySubTypeId, kopi, propertyFor) {
    var propertyForText = propertyFor == 1 ? "Sell" : "Rent";
    var url = "property/" + propertySubTypeName.replace(/[^a-zA-Z0-9]/g, '-') + '-for-' + propertyForText + '-in-'+cityName.trim() + '/' + 'city/'+ cityName.trim() + '/' +'listsubtype/' +  propertySubTypeId + '/' + 'kopi/'+ kopi + '/' + 'propertyfor/'+ propertyFor;
    this._router.navigateByUrl(url);
    window.scrollTo(0, 0);
  };

  onChangeGetPage() {
    this.operationInfo.searchModels = [];

    let searchData: PropertySearchModel = new PropertySearchModel();
    searchData.name = "City";
    searchData.value = this.searchByCity;
    searchData.condition = SearchConditionalOperators.And;
    searchData.symbol = SearchOperators.Like;
    this.operationInfo.searchModels.push(searchData);

    this.getDynamicPageRecords();
  }

  cityData: any;
  cityOperationInfo: any;
  async getCity() {
    this.cityOperationInfo = new OperationInfo();
    this.cityOperationInfo.pageIndex = 1;
    this.cityOperationInfo.pageSize = 0;
    this.cityOperationInfo.provideListResource = true;
    this.cityOperationInfo.sortingColumn = "Name";
    this.cityOperationInfo.sortingType = this.sortingTypes[SortingTypes.Asc];
    this.cityData = this._cityDataService.getOption();

    //ristrict round trip
    if (this.cityData == undefined || this.cityData.length == 0) {
      await this._propertyMasterService.getAllCity(this.cityOperationInfo).toPromise().then(
        data => {
          if (data != null)
          {
            this.cityData = data;
            this._cityDataService.setCityData(this.cityData);
            this._cityDataService.setOption(this.cityData);
          }
        },
        error => {
          this._commonFunctions.showError(this._toaster, error);
        }
      );
    }
  }
  cityDataOpen(): void {
    this.citydropdown = true;
  }  
}
