import { Injectable } from '@angular/core';
//import { Guid } from 'guid-typescript';
import { RequestMethodRepository } from '../common/RequestMethods.repository';
import { PostPropertyUrlConstants, PropertyListByTypeUrlConstants } from '../common/Url.Constant';
import { OperationInfo } from '../model/common.model';
import { PostPropertyModel, PropertyPhotosResource } from '../model/postPropertyModel';

@Injectable()
export class PostPropertyService {

  constructor(private httpRepository: RequestMethodRepository) {
  }

  getProperty(operationInfo: OperationInfo) {
    return this.httpRepository.GetAll(PostPropertyUrlConstants.getProperty, operationInfo);
  }

  getPropertyById(propertyId: string) {
    return this.httpRepository.Get(PostPropertyUrlConstants.getPropertyById.replace("{id}", propertyId.toString()));
  }

  postProperty(file: FormData) {
    return this.httpRepository.PostWithFile(PostPropertyUrlConstants.postProperty, file);
  }

  sendPostPropertyEmail(file: FormData) {
    return this.httpRepository.PutWithFile(PostPropertyUrlConstants.sendPostPropertyEmail, file);
  }

  updateProperty(file: FormData) {
    return this.httpRepository.PutWithFile(PostPropertyUrlConstants.updateProperty, file);
  }

  deletePropertyById(propertyId: string) {
    return this.httpRepository.Delete(PostPropertyUrlConstants.deletePropertyById.replace("{id}", propertyId.toString()));
  }

  activatePropertyById(propertyId: string, userId: string) {
    return this.httpRepository.Put(PostPropertyUrlConstants.activatePropertyById + "?id=" + propertyId + '&createdBy=' + userId,null);
  }

}

