import { Injectable } from '@angular/core';
import { RequestMethodRepository } from '../common/RequestMethods.repository';
import { ContactUsUrlConstants } from '../common/Url.Constant';
import { OperationInfo } from '../model/common.model';
import { ContactUsModel } from '../model/contactus.model';

@Injectable()
export class ContactUsService {

  constructor(private httpRepository: RequestMethodRepository) {
  }

  getContactUs(operationInfo: OperationInfo) {
    return this.httpRepository.GetAll(ContactUsUrlConstants.getContactUs, operationInfo);
  }

  getContactUsById(contactUsId: string) {
    return this.httpRepository.Get(ContactUsUrlConstants.getContactUsById.replace("{id}", contactUsId.toString()));
  }

  postContactUs(contactUs: ContactUsModel) {
    return this.httpRepository.Post(ContactUsUrlConstants.postContactUs, contactUs);
  }

  deleteContactUsId(contactUsId: string) {
    return this.httpRepository.Delete(ContactUsUrlConstants.deleteContactUsById.replace("{id}", contactUsId.toString()));
  }


}
