import { Injectable } from '@angular/core';
import { RequestMethodRepository } from '../common/RequestMethods.repository';
import { PropertyEnquiryUrlConstants } from '../common/Url.Constant';
import { OperationInfo } from '../model/common.model';
import { PropertyEnquiry } from '../model/propertyEnquiry.Model';

@Injectable()
export class PropertyEnquiryService {

  constructor(private httpRepository: RequestMethodRepository) {
  }

  getPropertyEnquiry(operationInfo: OperationInfo) {
    return this.httpRepository.GetAll(PropertyEnquiryUrlConstants.getPropertyEnquiry, operationInfo);
  }

  getPropertyEnquiryById(propertyEnquiryId: string) {
    return this.httpRepository.Get(PropertyEnquiryUrlConstants.getPropertyEnquiryById.replace("{id}", propertyEnquiryId.toString()));
  }

  postPropertyEnquiry(propertyEnquiry: PropertyEnquiry) {
    return this.httpRepository.Post(PropertyEnquiryUrlConstants.postPropertyEnquiry, propertyEnquiry);
  }

  sendEnquiryEmail(propertyEnquiry: PropertyEnquiry) {
    return this.httpRepository.Put(PropertyEnquiryUrlConstants.sendEnquiryEmail, propertyEnquiry);
  }

  deletePropertyEnquiryId(propertyEnquiryId: string) {
    return this.httpRepository.Delete(PropertyEnquiryUrlConstants.deletePropertyEnquiryById.replace("{id}", propertyEnquiryId.toString()));
  }


}
