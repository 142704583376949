import { Injectable } from '@angular/core';
import { RequestMethodRepository } from '../common/RequestMethods.repository';
import { DashboardUrlConstants } from '../common/Url.Constant';
import { OperationInfo } from '../model/common.model';
import { ListingPaymentModel, WarehousePaymentModel } from '../model/payment.model';

@Injectable()
export class DashboardService {

  constructor(private httpRepository: RequestMethodRepository) {
  }

  getRecord(id: string) {
    return this.httpRepository.Get(DashboardUrlConstants.getRecord.replace("{id}", id.toString()));
  }

}
