import { DecimalPipe } from "@angular/common";

export class SignUpModel {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  mobileNumber: string;
  mobileNumber2: string;
  profilePicture:string;
  gender:string;
  occupation:number;
  currentState:number;
  currentCity:number;
  currentAddress:string;
  isOwner:number;
  userTypeId:number;
  blocked:boolean;
  password: string;
  facebook:string;
  youtube:string;
  twitter:string;
  website:string;
}


export class UserModel {
  UserId: string;
  UserName: string;
  EmailAddress: string;
  ProfilePicture:string;
  Mobile: string;
  Blocked:boolean;
  Password: string;
  UserTypeId:number;
  IsDeleted: string;
}

export enum OccupationEnum {
  "Engineer" = 1,
  "Doctor",
  "Scientist",
  "Teacher",
  "Cunsultant",
  "Accountant",
  "Lawyer",
  "Architect",
  "Financial Analyst",
  "Artist",
  "Other"
}


export class SendSignUpEmailModel {
  Email: string;
  FirstName: string;
}