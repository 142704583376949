import { Component, OnInit, Inject, ElementRef, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-mat-confirm-dialog',
  templateUrl: './mat-confirm-dialog.component.html',
  styleUrls: ['./mat-confirm-dialog.component.css']
})
export class MatConfirmDialogComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data:any,
    public dialogRef: MatDialogRef<MatConfirmDialogComponent>) { }

  @ViewChild('noButton') noButton: ElementRef;

  ngOnInit() {
    // if(!this.data.isFocusOnYesButton)
    // {
    //   this.noButton.nativeElement.focus();
    // }
  }

   closeDialog() {
    this.dialogRef.close(false);
  }

}
