import { Injectable } from '@angular/core';
import { RequestMethodRepository } from '../common/RequestMethods.repository';
import { LoginUrlConstants, SignUpUrlConstants } from '../common/Url.Constant';
import { OperationInfo } from '../model/common.model';
import { SignUpModel, UserModel, SendSignUpEmailModel } from '../model/signup.model';

@Injectable()
export class SignUpService {

  constructor(private httpRepository: RequestMethodRepository) {
  }

  getAllProfile(operationInfo: OperationInfo) {
    return this.httpRepository.GetAll(SignUpUrlConstants.postSignup, operationInfo);
  }

  signUpNewUser(signUpModel: SignUpModel) {
    return this.httpRepository.Post(SignUpUrlConstants.postSignup, signUpModel);
  }

  getAllUserProfile(signUpModel: SignUpModel) {
    return this.httpRepository.Get(SignUpUrlConstants.postSignup);
  }

  getUserProfileById(id: string) {
    return this.httpRepository.Get(SignUpUrlConstants.getUserProfileById.replace("{id}", id));
  }

  getUserProfileByEmail(email: string) {
    return this.httpRepository.Get(SignUpUrlConstants.getUserProfileByEmail.replace("{email}", email));
  }

  deleteProfileById(id: string) {
    return this.httpRepository.Delete(SignUpUrlConstants.getUserProfileById.replace("{id}", id));
  }


  updateUserProfile(signUpModel: SignUpModel) {
    return this.httpRepository.Put(SignUpUrlConstants.updateUserProfile, signUpModel);
  }

  changePassword(signUpModel: SignUpModel) {
    return this.httpRepository.Put(SignUpUrlConstants.changePassword, signUpModel);
  }

  forgotPasswordSendEmail(signUpModel: SignUpModel) {
    return this.httpRepository.Put(SignUpUrlConstants.forgotpasswordsendemail, signUpModel);
  }

  forgotPassword(signUpModel: SignUpModel) {
    return this.httpRepository.Put(SignUpUrlConstants.forgotPassword, signUpModel);
  }

  sendSignUpEmail(sendSignUpEmailModel: SendSignUpEmailModel) {
    return this.httpRepository.Put(SignUpUrlConstants.sendSignUpEmail, sendSignUpEmailModel);
  }

  GetToken(model: SignUpModel) {
    return this.httpRepository.Post(LoginUrlConstants.getToken, model);
  }

  private _isAuthenticated: any;
  public set IsAuthenticated(value: any) {
    this._isAuthenticated = value;
  }
  public get IsAuthenticated() { return this._isAuthenticated; }
}
