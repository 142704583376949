<div class="footer-bg">

  <div class="container-fluid">
    <div class="row justify-content-center">
     
      <div class="col-xl-3 col-sm-8 col-12 form-group">
        <span class="" *ngIf="!isServer">
          <input (focus)="cityDataOpen()" list="lstCity" [(ngModel)]="searchByCity" (change)="onChangeGetPage()" maxlength="70"
            class="form-control footer-search" placeholder="City">
          <datalist id="lstCity">
            <option></option>
            <ng-container  *ngIf="citydropdown">
            <option *ngFor="let city of cityData" value={{city.name}}></option>
            </ng-container>
          </datalist>
        </span>
      </div>
     
    </div>
    <div class="row">
      <hr class="d-none d-md-block">
    </div>
    <div class="row">
      <div class="col-sm-4" *ngFor="let dynamicPage of dynamicPageRecords">
        <div class="dynamic-page-record">
          <a *ngIf="dynamicPage.kindOfPropertyId==0"
            (click)="navigateToDynamicPage(dynamicPage.propertySubTypeName,dynamicPage.cityName,dynamicPage.propertySubTypeId, dynamicPage.kindOfPropertyId, dynamicPage.propertyFor)">
            <span>{{dynamicPage.propertySubTypeName}} for {{dynamicPage.propertyFor==1?"Sell":"Rent"}} In
              {{dynamicPage.cityName}} </span>
          </a>
          <a *ngIf="dynamicPage.kindOfPropertyId>0"
            (click)="navigateToDynamicPage(dynamicPage.kindOfPropertyName,dynamicPage.cityName,dynamicPage.propertySubTypeId, dynamicPage.kindOfPropertyId, dynamicPage.propertyFor)">
            <span *ngIf="dynamicPage.kindOfPropertyId==0">{{dynamicPage.propertySubTypeName}} In
              {{dynamicPage.cityName}}</span>
            <span>{{dynamicPage.kindOfPropertyName}} for {{dynamicPage.propertyFor==1?"Sell":"Rent"}} In
              {{dynamicPage.cityName}} </span>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="copyright-wrap">
<div class="container">
  <div class="row footer-awards">
    <div class="col-md-6 col-12">
      <div class="copyright">© Warehousewale 2021 . All rights reserved</div>
      <!-- Awards:
      <span>
        <ul class="awards-list">
          <li>award1</li>
          <li>award2</li>
          <li>award3</li>
        </ul>
      </span> -->
    </div>
    <div class="col-md-6 col-12 social-sec">
      Follow Us On:
      <span>
        <ul class="awards-list mt-2 mt-sm-0">
          <li>
            <a href="https://wa.me/918115425077" target="_blank">
              <i class="fa fa-whatsapp" aria-hidden="true"></i>
            </a>
          </li>
          <li>
            <a href="https://www.facebook.com/warehousewalecom-589334177860826" target="_blank">
              <i class="fa fa-facebook-square" aria-hidden="true"></i>
            </a>
          </li>
          <li>
            <a href="#" target="_blank">
              <i class="fa fa-google-plus" aria-hidden="true"></i>
            </a>
          </li>
          <li>
            <a href="#" target="_blank">
              <i class="fa fa-twitter" aria-hidden="true"></i>
            </a>
          </li>
          <li>
            <a href="https://www.linkedin.com/in/ranvijay-singh-909b40177/" target="_blank">
              <i class="fa fa-linkedin-square" aria-hidden="true"></i>
            </a>
          </li>
          <li>
            <a href="https://www.instagram.com/warehouse_rent/" target="_blank">
              <i class="fa fa-instagram" aria-hidden="true"></i>
            </a>
          </li>
          <li>
            <a href="#" target="_blank">
              <i class="fa fa-youtube" aria-hidden="true"></i>
            </a>
          </li>
        </ul>
      </span>
    </div>
  </div>
</div>
</div>

