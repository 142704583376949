import { environment } from '../../environments/environment';

export class PropertyTypeUrlConstants {
  public static getPropertyType: string = environment.url + "/api/PropertyType";
}


export class PropertyListByTypeUrlConstants {
  public static getPropertyListByType: string = environment.url + "/api/PropertyListByType";
}

export class PropertyUnitUrlConstants {
  public static getPropertyUnits: string = environment.url + "/api/PropertyUnits";
}

export class PropertyAmenitiesByTypeUrlConstants {
  public static getPropertyAmenitiesByType: string = environment.url + "/api/AmenitiesByType";
}

export class CityUrlConstants {
  public static getState: string = environment.url + "/api/City/State";
  public static getCityByState: string = environment.url + "/api/City/{id}";
  public static getCity: string = environment.url + "/api/City";
  public static getCityById: string = environment.url + "/api/City/{id}";
}

export class CityLocalityUrlConstants {
  public static getCityLocality: string = environment.url + "/api/CitiesLocality";
  public static getCityLocalityById: string = environment.url + "/api/CitiesLocality/{id}";
  public static deleteCityLocalityById: string = environment.url + "/api/CitiesLocality/{id}";
  public static postCityLocality: string = environment.url + "/api/CitiesLocality";
}

export class PostPropertyUrlConstants {
  public static getProperty: string = environment.url + "/api/Property";
  public static getPropertyById: string = environment.url + "/api/Property/{id}";
  public static deletePropertyById: string = environment.url + "/api/Property/{id}";
  public static postProperty: string = environment.url + "/api/Property";
  public static updateProperty: string = environment.url + "/api/Property";
  public static activatePropertyById: string = environment.url + "/api/Property/Activate";
  public static sendPostPropertyEmail: string = environment.url + "/api/Property/SendPostPropertyEmail";
}

export class SignUpUrlConstants {
  public static postSignup: string = environment.url + "/api/userProfile";
  public static getUserProfile: string = environment.url + "/api/userProfile";
  public static getUserProfileById: string = environment.url + "/api/userProfile/{id}";
  public static getUserProfileByEmail: string = environment.url + "/api/userProfile/{email}";
  public static updateUserProfile: string = environment.url + "/api/userProfile";
  public static changePassword: string = environment.url + "/api/userProfile/changepassword";
  public static forgotpasswordsendemail: string = environment.url + "/api/userProfile/forgotpasswordsendemail";
  public static forgotPassword: string = environment.url + "/api/userProfile/forgotpassword";
  public static sendSignUpEmail: string = environment.url + "/api/userProfile/sendSignUpEmail";
}

export class KindOfPropertyUrlConstants {
  public static getAllKindOfProperty: string = environment.url + "/api/KindOfProperty";
}

export class LoginUrlConstants {
  public static getToken: string = environment.url + "/api/Login";
}

export class ContactUsUrlConstants {
  public static getContactUs: string = environment.url + "/api/ContactUs";
  public static getContactUsById: string = environment.url + "/api/ContactUs/{id}";
  public static deleteContactUsById: string = environment.url + "/api/ContactUs/{id}";
  public static postContactUs: string = environment.url + "/api/ContactUs";
}


export class DynamicPageContentUrlConstants {
  public static get: string = environment.url + "/api/DynamicPageContent";
  public static getById: string = environment.url + "/api/DynamicPageContent/{id}";
  public static deleteById: string = environment.url + "/api/DynamicPageContent/{id}";
  public static post: string = environment.url + "/api/DynamicPageContent";
  public static put: string = environment.url + "/api/DynamicPageContent";
}


export class PaymentUrlConstants {
  public static getListing: string = environment.url + "/api/ListingPayment";
  public static getByIdListing: string = environment.url + "/api/ListingPayment/{id}";
  public static postListing: string = environment.url + "/api/ListingPayment";
  public static putListing: string = environment.url + "/api/ListingPayment";

  public static getWarehouse: string = environment.url + "/api/WarehousePayment";
  public static getByIdWarehouse: string = environment.url + "/api/WarehousePayment/{id}";
  public static postWarehouse: string = environment.url + "/api/WarehousePayment";
  public static putWarehouse: string = environment.url + "/api/WarehousePayment";
}


export class DashboardUrlConstants {
  public static getRecord: string = environment.url + "/api/Dashboard/{id}";
}

export class PropertyEnquiryUrlConstants {
  public static getPropertyEnquiry: string = environment.url + "/api/PropertyEnquiry";
  public static getPropertyEnquiryById: string = environment.url + "/api/PropertyEnquiry/{id}";
  public static deletePropertyEnquiryById: string = environment.url + "/api/PropertyEnquiry/{id}";
  public static postPropertyEnquiry: string = environment.url + "/api/PropertyEnquiry";
  public static sendEnquiryEmail: string = environment.url + "/api/PropertyEnquiry/sendEnquiryEmail";
}

export class PropertyViewContactUrlConstants {
  public static getPropertyViewContact: string = environment.url + "/api/PropertyViewContact";
  public static getPropertyViewContactById: string = environment.url + "/api/PropertyViewContact/{id}";
  public static deletePropertyViewContactById: string = environment.url + "/api/PropertyViewContact/{id}";
  public static postPropertyViewContact: string = environment.url + "/api/PropertyViewContact";
}


export class ProjectGalleryUrlConstants {
  public static getProjectGallery: string = environment.url + "/api/ProjectGallery";
  public static getProjectGalleryById: string = environment.url + "/api/ProjectGallery/{id}";
  public static deleteProjectGalleryById: string = environment.url + "/api/ProjectGallery/{id}";
  public static postProjectGallery: string = environment.url + "/api/ProjectGallery";
}

