import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { CommonFunctions } from '../common/CommonFunction';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
//import { Sub } from 'rxjs/Subject';

@Injectable({
  providedIn: 'root'
})
export class EncryptionService {

  //private messageSource = new Subject<String>();
  constructor(private _commonFunction: CommonFunctions, private router: Router, private toastrService: ToastrService) { }

  encryptValue(textToEncrypt: string): string {

    // if(textToEncrypt.length>0 && textToEncrypt!=null)
    // {
    //   var encrypted = CryptoJS.AES.encrypt(textToEncrypt,this._commonFunction.securePBKDF2Key).toString();
    //   ​return encrypted;
    // }
    return textToEncrypt;
  }

  decryptValue(cipherText: string): string {
    // try{
    // if( cipherText!=null && cipherText.length>0)
    // {
    //  var bytes  = CryptoJS.AES.decrypt(cipherText, this._commonFunction.securePBKDF2Key).toString(CryptoJS.enc.Utf8);
    //   return bytes.toString();
    // }
    // }
    // catch(error){
    //   this._commonFunction.showError(this.toastrService, error);
    //   this.router.navigate(['/login']);
    // }
    return cipherText;
  }

}
