import { BrowserModule, Title, MetaDefinition, Meta } from '@angular/platform-browser';
import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { NgxSpinnerModule } from 'ngx-spinner';
import { MatDialogModule } from '@angular/material/dialog';
import { ToastrModule } from 'ngx-toastr';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './modules/shared/header/header/header.component';
import { FooterComponent } from './modules/shared/footer/footer/footer.component';
import { PostPropertyService } from './services/post-property.service';
import { RequestMethodRepository } from './common/RequestMethods.repository';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { CommonFunctions } from './common/CommonFunction';
import { PropertyMasterService } from './services/property-master.service'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SecureStorageService } from './services/secure-storage.service';
import { SignUpService } from './services/signup.service'
import { ContactUsService } from './services/contactus.service'
import { DialogService } from './services/dialog.service'
import { DynamicPageContentService } from './services/dynamic-page-content.service'
import { KindOfPropertyService } from './services/kind-of-property.service';
import { ImageDragDirective } from './modules/file-upload/image-drag.directive';
import { MatConfirmDialogComponent } from './modules/mat-confirm-dialog/mat-confirm-dialog.component';

import { HashLocationStrategy, LocationStrategy, PathLocationStrategy, APP_BASE_HREF } from '@angular/common';
import { PaymentService } from './services/payment.service';
import { AuthGuardService } from './services/auth-guard.service';
import { DashboardService } from './services/dashboard.service';
import { PropertyEnquiryService } from './services/property-enquiry.service';
import { PropertyViewContactService } from './services/property-view-contact.service';
import { LowerCaseUrlSerializer } from './services/lower-case-url-serializer.service';
import { UrlSerializer } from '@angular/router';
import { CityDataService, PropertyListByTypeDataService, PropertyUnitsDS } from './services/shared-data.service';
import { JwPaginationModule } from 'jw-angular-pagination';
import { CityLocalityService } from './services/city-locality.service';
import { ProjectGalleryService } from './services/project-gallery.services';
import { SignupComponent } from './modules/signup/signup.component';
import { TransferHttpCacheModule } from '@nguniversal/common';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    SignupComponent,
    ImageDragDirective,
    MatConfirmDialogComponent
  ],
  imports: [
    TransferHttpCacheModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    HttpClientModule,
    FormsModule,
    ToastrModule.forRoot({ preventDuplicates: true }), NgxSpinnerModule,
    BrowserAnimationsModule,
    MatDialogModule,
    AppRoutingModule,
    JwPaginationModule,
  ],
  exports: [],
  providers: [RequestMethodRepository,
    PostPropertyService, HttpClient,
    CommonFunctions, PropertyMasterService,
    SecureStorageService,
    SignUpService, KindOfPropertyService,
    DialogService, ContactUsService,
    DynamicPageContentService, Title, Meta,
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    PaymentService,
    AuthGuardService,
    DashboardService,
    PropertyEnquiryService,
    PropertyViewContactService,
    {
      provide: UrlSerializer,
      useClass: LowerCaseUrlSerializer
    },
    CityDataService,
    PropertyListByTypeDataService,
    PropertyUnitsDS,
    CityLocalityService,
    ProjectGalleryService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {

}

