import { Injectable } from '@angular/core';
import {EncryptionService} from './encryption.service';
@Injectable({
  providedIn: 'root'
})
export class SecureStorageService {

  constructor(private encryptService:EncryptionService ) { }

  setValue(key:string,value:string) {
   localStorage.setItem(key, value);
    return value;
  }

  getValue(key:any): string {
    var value= localStorage.getItem(key);
   return value;
  }

}
