<div class="reg-banner">
  <!-- <img src="assets/images/registration.jpg" alt="registration.jpg"> -->
</div>
<div class="container registration signup-container">
  <div class="row">
    <div class="col-12">
      <div class="p-3 signup-st">
        <ul class="nav nav-tabs" id="myTab" role="tablist">
          <!-- <li class="nav-item">
            <a class="nav-link " id="home-tab" data-toggle="tab" href="#login" role="tab" aria-controls="home"
              aria-selected="true">Login</a>
          </li> -->
          <li class="nav-item">
            <a class="nav-link signup-text active" id="profile-tab" data-toggle="tab" href="#signup" role="tab" aria-controls="profile"
              aria-selected="false">Sign-up</a>
          </li>
        </ul>
        <div class="tab-content" id="myTabContent">
          <!-- <div class="tab-pane fade show active" id="login" role="tabpanel" aria-labelledby="login-tab">
            <form>
              <div class="form-group">
                <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
                  placeholder="Enter email">
                <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small>
              </div>
              <div class="form-group">
                <input type="password" class="form-control" id="exampleInputPassword1" placeholder="Password">
              </div>
              <div class="form-check">
                <input type="checkbox" class="form-check-input" id="exampleCheck1">
                <label class="form-check-label" for="exampleCheck1">Check me out</label>
              </div>
              <button type="submit" class="btn btn-primary">Submit</button>
            </form>
          </div> -->
          <div class="tab-pane fade show active" id="signup" role="tabpanel" aria-labelledby="signup-tab">
            <div class="signup-body">
              <div class="row ">
                <div class="col-md-6 form-group">
                  <input type="text" class="form-control" maxlength="40" placeholder="First Name"
                    [(ngModel)]="signup.firstName">
                </div>

                <div class="col-md-6 form-group">
                  <input type="text" class="form-control" maxlength="40" placeholder="Last Name"
                    [(ngModel)]="signup.lastName">
                </div>
              </div>
              <div class="row ">
                <div class="col-md-12 form-group">
                  <input type="text" class="form-control" maxlength="75" placeholder="E-mail" [(ngModel)]="signup.email">
                </div>
              </div>
              <div class="row ">
                <div class="col-md-12 form-group">
                  <input type="text" class="form-control" maxlength="10" minlength="10" placeholder="Mobile Number"
                    [(ngModel)]="signup.mobileNumber">
                </div>
              </div>
              <div class="row ">
                <div class="col-md-12 form-group">
                  <select class="form-control" [(ngModel)]="signup.isOwner" [disabled]="isViewOnly">
                    <option value="0">You are Owner/Broker?</option>
                    <option value="1">Owner</option>
                    <option value="2">Broker</option>
                  </select>
                </div>
              </div>
              <div class="row ">
                <div class="col-md-12 form-group">
                  <input type="password" class="form-control" maxlength="25" placeholder="Password"
                    [(ngModel)]="signup.password">
                </div>
              </div>
              <div class="row ">
                <div class="col-md-12 form-group">
                  <input type="password" class="form-control" maxlength="25" placeholder="Confirm Password"
                    [(ngModel)]="confirmPassword">
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 form-group signup-button text-right">
                <input type="button" class="btn btn-success" (keyup.enter)="signUpNewUser()" value="Sign-up"
                  (click)="signUpNewUser()">
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>