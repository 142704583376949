import { AfterViewInit, Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { SignUpService } from '../../../../services/signup.service';
import { SignUpModel } from '../../../../model/signup.model';
import { CommonFunctions } from '../../../../common/CommonFunction';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { environment } from '../../../../../environments/environment';
import { DialogService } from '../../../../services/dialog.service';
import { ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent implements OnInit,AfterViewInit {

  cridentials: SignUpModel;
  signup: SignUpModel;
  forgotPass: SignUpModel;
  forgotConfirmPassword: string;
  isAuthenticated: boolean = false;
  userEmail: string;
  userMobile: string;
  userName: string;
  userTypeId: number; //1 admin, 2 Manager, 3 Operator, 4 Agent, 5 Owner
  constructor(private _router: Router, private _dialogService: DialogService, private _signUpService: SignUpService, private _toaster: ToastrService, public _commonFunctions: CommonFunctions) {
    this.isAuthenticated = this._commonFunctions.isAuthenticated();
    if (this.isAuthenticated) {
      this.userEmail = localStorage.getItem('userEmail');
      this.userMobile = localStorage.getItem('userMobile');
      this.userName = localStorage.getItem('userName');
      this.userTypeId = Number(localStorage.getItem('userType'));
    } else {
      this.userEmail = null;
      this.userMobile = null;
      this.userName = null;
    }

    this._dialogService.onChangePostProperty.subscribe((data: any) => {
      this.userTypeId = Number(localStorage.getItem(data.userTypeId));
      this.userEmail = localStorage.getItem(data.emailAddress);
      this.userMobile = localStorage.getItem(data.mobile);
      this.userName = localStorage.getItem(data.userName);
      this.isAuthenticated = this._commonFunctions.isAuthenticated();
    })

  }

 
  ngAfterViewInit(): void {
    $('.openbtn').click(function () {
      $("#mySidenav").show(200);
    });
    $('.closebtn').click(function () {
      $("#mySidenav").hide(300);
    });
    $("#mySidenav li").click(function () {
      $('#mySidenav').hide(400);
    });

    $("#mySidenav a").click(function () {
      $('#mySidenav').hide(400);
    });

    window.onclick = function (event) {
      if (event.target.id != "spanOpenbtn") {
        $("#mySidenav").hide();
      }
    }

    $("#LoginTab").hide();
    $("#LoginTab").removeClass('active');
  
  }

  ngOnInit(): void {
     
    // this._commonFunctions.testApi().subscribe(res=> {

    // })
    this.cridentials = new SignUpModel();
    this.isAuthenticated = this._commonFunctions.isAuthenticated();
    this.forgotPass = new SignUpModel();
  }

  openLoginModel() {
    $("#LoginTab").show();
    $("#LoginTab").addClass('active');
  }

  closeModel() {
    $("#LoginTab").hide();
    $("#LoginTab").removeClass('active');
  }
  //login
  login() {

    var that = this;
    //this._spinner.show();
    this._signUpService.GetToken(this.cridentials).subscribe(
      data => {
        localStorage.setItem('access_token', data.accessToken);
        localStorage.setItem('userToken', data.accessToken);
        localStorage.setItem('userEmail', data.emailAddress);
        localStorage.setItem('userMobile', data.mobile);
        localStorage.setItem('userName', data.userName);
        localStorage.setItem('userId', data.userId);
        localStorage.setItem('userProfileImage', data.profilePicture);
        localStorage.setItem('userType', data.userTypeId);
        this.userTypeId = data.userTypeId;
        localStorage.setItem('isAuthenticated', 'true');
        this.userEmail = data.emailAddress;
        this.userMobile = data.mobile;
        this.userName = data.userName;
        this.closeModel();
        this.isAuthenticated = this._commonFunctions.isAuthenticated();

        var url = window.location.pathname; //+ decodeURIComponent(location.search);
        url = url.split('/').length > 0 ? url.split('/')[1] : "";
        if (url != "post-property" && url != "search-property" && url != "view-property") {
          if (data.userTypeId == 1) {
            this._router.navigateByUrl('/admin')
              .then(() => {
                window.location.reload();
              });
          } else if (data.userTypeId == 2 || data.userTypeId == 3) {
            this._router.navigateByUrl('/employee')
              .then(() => {
                window.location.reload();
              });
          } else if (data.userTypeId == 4 || data.userTypeId == 5) {
            this._router.navigateByUrl('/user/list-property')
              .then(() => {
                window.location.reload();
              });
          }
        }
      },
      error => {
        this._commonFunctions.showError(this._toaster, error);
      });
  }


  logout() {
    localStorage.setItem('access_token', null);
    localStorage.setItem('userToken', null);
    localStorage.setItem('userEmail', null);
    localStorage.setItem('userId', null);
    localStorage.setItem('userMobile', null);
    localStorage.setItem('userName', null);
    localStorage.setItem('userProfileImage', "");
    localStorage.setItem('isAuthenticated', 'false');
    localStorage.setItem('userType', null);
    this.userEmail = null;
    this.userMobile = null;
    this.userName = null;
    this.closeModel();

    this.isAuthenticated = this._commonFunctions.isAuthenticated();
    this._router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this._router.navigate(['/']);
    });

  }


  forgotPasswordSendEmail() {
    if (!this._commonFunctions.validateRequired(this.forgotPass.email, "Email required")) {
      this._toaster.error(this._commonFunctions.validationMessage);
      return false;
    }

    this._signUpService.forgotPasswordSendEmail(this.forgotPass).subscribe(
      data => {
        this._toaster.success("OTP has been send on your email");
        this.signup = new SignUpModel();
      },
      error => {
        this._commonFunctions.showError(this._toaster, error);
      }
    );
  }

  forgotPassword() {
    if (!this._commonFunctions.validateRequired(this.forgotPass.email, "Email required")) {
      this._toaster.error(this._commonFunctions.validationMessage);
      return false;
    } else if (!this._commonFunctions.validateRequired(this.forgotPass.password, "password required")) {
      this._toaster.error(this._commonFunctions.validationMessage);
      return false;
    } else if (!this._commonFunctions.validateRequired(this.forgotConfirmPassword, "Confirm Password required")) {
      this._toaster.error(this._commonFunctions.validationMessage);
      return false;
    } else if (this.forgotConfirmPassword != this.forgotPass.password) {
      this._toaster.error("Password and Confirm Password does not match");
      return false;
    } else if (!this._commonFunctions.validateRequired(this.forgotPass.website, "OTP required")) {
      this._toaster.error(this._commonFunctions.validationMessage);
      return false;
    }

    this._signUpService.forgotPassword(this.forgotPass).subscribe(
      data => {
        this._toaster.success("Password successfully updated");
        this.forgotConfirmPassword = "";
        this.forgotPass = new SignUpModel();
        $("#login").addClass('active');
        $("#forgotpassword").removeClass('active');
        $("#home-tab").addClass('active');
        $("#forgot-tab").removeClass('active');
      },
      error => {
        this._commonFunctions.showError(this._toaster, error);
      }
    );

  }

}


