<div class="container-fluid header-top">
  <div class="row position">
    <div class="col-xl-6 col-md-3 col-sm-12 col-xs-12">
      <div class="main-logo">
        <a routerLink="/"><img class="img-responsive img-logo" src="assets/images/logo.png" alt="top 10 real estate companies in india - real estate websites in india -best real estate websites india - real estate india - best property sites in india - top real estate companies in india- real estate companies in india"></a>
      </div>
      <div class="location-dropdown">
        <div>
        </div>
      </div>
    </div>
    <div class="col-xl-6 col-md-9 col-sm-12 col-xs-12">
      <div class="row">
        <div class="col-xl-4 col-md-5">
          <div class="postproperty"><button class="btn" routerLink="/post-property">Post Property</button>
          </div>
        </div>
        <div class="col-xl-6 col-md-5">
          <ul class="main-menu">
            <li><a routerLink="/">Listing</a></li>
            <li><a routerLink="/">Faq</a></li>
            <li><a routerLink="/contact-us" routerLinkActive="child-page">Contact</a></li>
          </ul>
        </div>
        <div class="col-xl-2 col-md-2 main-menu-icon">

          <div id="mySidenav" class="sidenav">
            <a href="javascript:void(0)" class="closebtn">&times;</a>
            <div class="px-3" *ngIf="isAuthenticated">
              Welcome, {{userName}}
            </div>
            <ul>
              <li *ngIf="isAuthenticated && userTypeId == 1"><a routerLink="/admin">Dashboard</a></li>
              <li *ngIf="isAuthenticated && (userTypeId == 2 || userTypeId == 3)"><a
                  routerLink="/employee">Dashboard</a></li>
              <li *ngIf="isAuthenticated && (userTypeId == 4 || userTypeId == 5)"><a routerLink="/user">Dashboard</a>
              </li>
              <li><a routerLink="/about">About</a></li>
              <!-- <li><a routerLink="/services">Services</a></li> -->
              <li><a routerLink="/privacy-policy">Privacy-Policy</a></li>
              <li *ngIf="!isAuthenticated"><a routerLink="/sign-up">Sign-up</a></li>
              <li *ngIf="!isAuthenticated"><a (click)="openLoginModel()">Login</a></li>
              <li><a routerLink="/contact-us">Contact</a></li>
              <!-- <li *ngIf="isAuthenticated && userTypeId == 1"><a routerLink="/register-employee">Register New
                  Employee</a></li> -->
              <!-- <li *ngIf="isAuthenticated && userTypeId == 1"><a routerLink="/view-employee">View Employee</a></li> -->
              <li *ngIf="isAuthenticated"><a (click)="logout()">LogOut</a></li>
            </ul>
          </div>
          <span style="font-size:30px;cursor:pointer" id="spanOpenbtn" class="openbtn">&#9776; </span>
          <!-- <i class="fa fa-bars" aria-hidden="true"></i>-->
        </div>
      </div>
    </div>
  </div>
  <div>
    <div class="modal" tabindex="-1" role="dialog" id="LoginTab">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" (click)="closeModel()" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div role="tabpanel">
              <!-- Nav tabs -->
              <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item">
                  <a class="nav-link active" id="home-tab" data-toggle="tab" href="#login" role="tab"
                    aria-controls="home" aria-selected="true">Login</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link " id="profile-tab" data-toggle="tab" href="#signup" role="tab"
                    aria-controls="profile" aria-selected="false">Sign-up</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link " id="forgot-tab" data-toggle="tab" href="#forgotpassword" role="tab"
                    aria-controls="profile" aria-selected="false">Forgot Password</a>
                </li>
              </ul>
              <!-- Tab panes -->
              <div class="tab-content">
                <div role="tabpanel" class="tab-pane active" id="login">
                  <br>
                  <div class="col-md-12">
                    <div class="form-group">
                      <div class="row">
                        <div class="col-md-4">
                          <h6>User Id</h6>
                        </div>
                        <div class="col-md-8">
                          <input type="text" class="form-control" [(ngModel)]="cridentials.email" placeholder="E-mail">
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <div class="row">
                        <div class="col-md-4">
                          <h6>Password</h6>
                        </div>
                        <div class="col-md-8">
                          <input type="password" class="form-control" [(ngModel)]="cridentials.password"
                            placeholder="Password">
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12 text-right">
                    <button type="button" class="btn btn-primary" (keyup.enter)="login()"
                      (click)="login()">Login</button>
                  </div>
                </div>
                <div role="tabpanel" class="tab-pane" id="signup">
                  <app-signup></app-signup>
                </div>
                <div role="tabpanel" class="tab-pane" id="forgotpassword">
                  <br>
                  <div class="col-md-12">
                    <div class="form-group">
                      <div class="row">
                        <div class="col-md-4">
                          <h6>User Id</h6>
                        </div>
                        <div class="col-md-8">
                          <input type="text" class="form-control" [(ngModel)]="forgotPass.email" placeholder="E-mail">
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <div class="row">
                        <div class="col-md-4">
                         
                        </div>
                        <div class="col-md-8">
                          <button class="btn btn-danger" (click)="forgotPasswordSendEmail()"> Send OTP</button>
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <div class="row">
                        <div class="col-md-4">
                          <h6>Password</h6>
                        </div>
                        <div class="col-md-8">
                          <input type="password" class="form-control" [(ngModel)]="forgotPass.password"
                            placeholder="Password">
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <div class="row">
                        <div class="col-md-4 pr-0">
                          <h6>Confirm Password</h6>
                        </div>
                        <div class="col-md-8">
                          <input type="password" class="form-control" [(ngModel)]="forgotConfirmPassword"
                            placeholder="Confirm Password">
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <div class="row">
                        <div class="col-md-4">
                          <h6>OTP</h6>
                        </div>
                        <div class="col-md-8">
                          <input type="text" class="form-control" [(ngModel)]="forgotPass.website" placeholder="OTP">
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12 text-right">
                    <button type="button" class="btn btn-primary" (click)="forgotPassword()">Submit</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="closeModel()">Close</button>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</div>
