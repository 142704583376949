import { Injectable } from '@angular/core';
import { RequestMethodRepository } from '../common/RequestMethods.repository';
import { PaymentUrlConstants } from '../common/Url.Constant';
import { OperationInfo } from '../model/common.model';
import { ListingPaymentModel, WarehousePaymentModel } from '../model/payment.model';

@Injectable()
export class PaymentService {

  constructor(private httpRepository: RequestMethodRepository) {
  }

  getAllListingPayment(operationInfo: OperationInfo) {
    return this.httpRepository.GetAll(PaymentUrlConstants.getListing, operationInfo);
  }

  getListingPaymentById(id: string) {
    return this.httpRepository.Get(PaymentUrlConstants.getByIdListing.replace("{id}", id.toString()));
  }

  postListingPayment(listingPaymentModel: ListingPaymentModel) {
    return this.httpRepository.Post(PaymentUrlConstants.postListing, listingPaymentModel);
  }

  // deleteListingPaymentId(id: string) {
  //   return this.httpRepository.Delete(PaymentUrlConstants.lis.replace("{id}", id.toString()));
  // }

  getAllWarehousePayment(operationInfo: OperationInfo) {
    return this.httpRepository.GetAll(PaymentUrlConstants.getWarehouse, operationInfo);
  }

  getWarehousePaymentById(id: string) {
    return this.httpRepository.Get(PaymentUrlConstants.getByIdWarehouse.replace("{id}", id.toString()));
  }

  postWarehousePayment(warehousePaymentModel: WarehousePaymentModel) {
    return this.httpRepository.Post(PaymentUrlConstants.postWarehouse, warehousePaymentModel);
  }
}
