import { Injectable } from '@angular/core';
import { RequestMethodRepository } from '../common/RequestMethods.repository';
import { LoginUrlConstants, KindOfPropertyUrlConstants } from '../common/Url.Constant';
import { OperationInfo } from '../model/common.model';
import { SignUpModel, UserModel } from '../model/signup.model';

@Injectable()
export class KindOfPropertyService {

  constructor(private httpRepository: RequestMethodRepository) {
  }

  getAllKindOfProperty(operationInfo : OperationInfo) {
    return this.httpRepository.GetAll(KindOfPropertyUrlConstants.getAllKindOfProperty, operationInfo);
  }

}
