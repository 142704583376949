import { Injectable } from '@angular/core';
//import { Guid } from 'guid-typescript';
import { RequestMethodRepository } from '../common/RequestMethods.repository';
import { ProjectGalleryUrlConstants } from '../common/Url.Constant';
import { OperationInfo } from '../model/common.model';
import { ProjectGalleryPhotos, ProjectGallery } from '../model/projectGallery.model';

@Injectable()
export class ProjectGalleryService {

  constructor(private httpRepository: RequestMethodRepository) {
  }

  getProjectGallery(operationInfo: OperationInfo) {
    return this.httpRepository.GetAll(ProjectGalleryUrlConstants.getProjectGallery, operationInfo);
  }

  getProjectGalleryById(propertyId: string) {
    return this.httpRepository.Get(ProjectGalleryUrlConstants.getProjectGalleryById.replace("{id}", propertyId.toString()));
  }

  postProjectGallery(file: FormData) {
    return this.httpRepository.PostWithFile(ProjectGalleryUrlConstants.postProjectGallery, file);
  }

  updateProjectGallery(file: FormData) {
    return this.httpRepository.PutWithFile(ProjectGalleryUrlConstants.postProjectGallery, file);
  }

  deleteProjectGalleryById(propertyId: string) {
    return this.httpRepository.Delete(ProjectGalleryUrlConstants.deleteProjectGalleryById.replace("{id}", propertyId.toString()));
  }

}

