import { Injectable } from '@angular/core';
import { RequestMethodRepository } from '../common/RequestMethods.repository';
import { DynamicPageContentUrlConstants } from '../common/Url.Constant';
import { OperationInfo } from '../model/common.model';
import { DynamicPageContent } from '../model/pageContent.model';

@Injectable()
export class DynamicPageContentService {

  constructor(private httpRepository: RequestMethodRepository) {
  }

  get(operationInfo: OperationInfo) {
    return this.httpRepository.GetAll(DynamicPageContentUrlConstants.get, operationInfo);
  }

  getById(dynamicPageContentId: string) {
    return this.httpRepository.Get(DynamicPageContentUrlConstants.getById.replace("{id}", dynamicPageContentId.toString()));
  }

  post(dynamicPageContent: DynamicPageContent) {
    return this.httpRepository.Post(DynamicPageContentUrlConstants.post, dynamicPageContent);
  }

  put(dynamicPageContent: DynamicPageContent) {
    return this.httpRepository.Put(DynamicPageContentUrlConstants.put, dynamicPageContent);
  }


  deleteById(dynamicPageContentId: string) {
    return this.httpRepository.Delete(DynamicPageContentUrlConstants.deleteById.replace("{id}", dynamicPageContentId.toString()));
  }


}
