import { Injectable } from '@angular/core';
import { CanActivate, Router, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { CommonFunctions } from '../common/CommonFunction';


@Injectable()
export class AuthGuardService implements CanActivate {

  constructor(private router: Router,public _commonFunctions: CommonFunctions)
  { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const isLoggedIn = this._commonFunctions.isAuthenticated();; // ... your login logic here
    if (isLoggedIn) {
      return true;
    } else {
      this.router.navigate(['/login']);
      return false;
    }
  }

}
