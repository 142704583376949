<div class="modal mat-confirm-dialog" role="dialog" style="display: block">
  <div class="modal-dialog" cdkDrag>
    <div class="modal-content modalTop">
      <div class="modal-header header" cdkDragHandle>
        <span class="title">Please confirm</span>
      </div>
      <div class="modal-body">
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-2 text-right">
              <img class="mat-question-icon" style="height: 40px;" src="../assets/images/question-icon.png">
            </div>
            <div class="col-md-10 mat-message-body"><span class="content-span full-width white-space-pre-line">{{data.message}}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer footer" cdkDragHandle>

        <span class="col-md-12 text-center">
          <button class="btn btn-primary" mat-flat-button id="yes-button" [mat-dialog-close]="true">{{data.yesButtonText}}</button>
          &nbsp;
          <button #noButton class="btn btn-primary" mat-flat-button id="no-button" [mat-dialog-close]="false">{{data.noButtonText}}</button>
        </span>

      </div>
    </div>
  </div>
</div>
