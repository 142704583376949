import { Injectable } from '@angular/core';
import { RequestMethodRepository } from '../common/RequestMethods.repository';
import { PropertyViewContactUrlConstants } from '../common/Url.Constant';
import { OperationInfo } from '../model/common.model';
import { PropertyViewContactModel } from '../model/propertyViewContact.model';

@Injectable()
export class PropertyViewContactService {

  constructor(private httpRepository: RequestMethodRepository) {
  }

  getPropertyViewContact(operationInfo: OperationInfo) {
    return this.httpRepository.GetAll(PropertyViewContactUrlConstants.getPropertyViewContact, operationInfo);
  }

  getPropertyViewContactById(propertyViewContactId: string) {
    return this.httpRepository.Get(PropertyViewContactUrlConstants.getPropertyViewContactById.replace("{id}", propertyViewContactId.toString()));
  }

  postPropertyViewContact(propertyViewContact: PropertyViewContactModel) {
    return this.httpRepository.Post(PropertyViewContactUrlConstants.postPropertyViewContact, propertyViewContact);
  }

  deletePropertyViewContactId(propertyViewContactId: string) {
    return this.httpRepository.Delete(PropertyViewContactUrlConstants.deletePropertyViewContactById.replace("{id}", propertyViewContactId.toString()));
  }


}
