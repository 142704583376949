export class PropertySearchModel {
  name: string;
  value: any;
  condition: SearchConditionalOperators;
  symbol: SearchOperators;
}

export enum SearchConditionalOperators {
  And,
  Or,
  None
}

export enum SearchOperators {
  Like,
  NotLike,
  Equal,
  NotEqual,
  LessThan,
  GreaterThan,
  LessThanEqual,
  GreaterThanEqual,
  In,
  NotIn,
  Between
}

export class CountryModel {
  id: number;
  name: string;
  twoCharCode: string;
  threeCharCode: string;
}

export class OperationInfo {
  pageIndex: number = -1;
  pageSize: number = 0;
  provideListResource: boolean = false;
  sortingColumn: string;
  sortingType: SortingTypes = SortingTypes.None;
  searchModels: PropertySearchModel[];
}

export enum OperationTypes {
  All,
  Partial
}

export enum SortingTypes {
  None,
  Asc,
  Desc
}


export class PagingButtonUrl {
  first: string;
  previous: string;
  next: string;
  last: string;
  pageIndexText: string;
  isNextDisabled: boolean;
  isPreviousDisabled: boolean;
}

export class UserData {
  userToken: string;
  refreshToken: string;
  isAuthenticated: boolean;
  userId: number;
  roles: string;
  country?: string;
  email?: string;
  displayName?: string;
  accessRights?: AccessRights[];
}

export class AccessRights {
  functionId: number;
  userRoleId: number;
  modelId: number;
  functionName: string;
  privileges: PrivilegeModel;
}

export class PrivilegeModel {
  view: boolean = false;
  add: boolean = false;
  edit: boolean = false;
  request: boolean = false;
  logicalDelete: boolean = false;
  physicalDelete: boolean = false;
  override: boolean = false;
  import: boolean = false;
  export: boolean = false;
}


export enum PagingButton {
  first,
  previous,
  next,
  last
}


export class PropertySearchInfo {
  searchModels: PropertySearchModel[];
  operationInfo: OperationInfo;
}

export class ResourceListResponse {
  resources: any;
  recordCount: number;
}


export class QueryParameters {
  query: any;
}
