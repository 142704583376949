import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import 'rxjs/operators';

@Injectable()
export class RequestMethodRepository {
  constructor(protected http: HttpClient) {
  }

  Get(url: string, id?: string): Observable<any> {
    if (id) {
      return this.http.get(this.GetUrlWithId(url, id), {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json; charset=UTF-8',
          //'Org': 'connepment.uat',
          'Authorization': 'Bearer ' + localStorage.getItem('access_token')
        }
      });//.map((response:Response)=><any>response.json()).catch(this.handleError);
    } else {
      return this.http.get(url, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json; charset=UTF-8',
          //'Org': 'connpment.uat',
          'Authorization': 'Bearer ' + localStorage.getItem('access_token')
        }
      });//.map((response:Response)=><any>response.json()).catch(this.handleError);
    }
  }

  GetAll(url: string, data: object): Observable<any> {
    if (data == null)
      return;

    let parameters = this.GetHttpParams(data);
    return this.http.get(url, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json; charset=UTF-8',
        'Authorization': 'Bearer ' + localStorage.getItem('access_token')
      },
      params: parameters
    });
  }

  PostWithFile(url: string, body: any): Observable<any> {
    return this.http.post(url, body, {
      headers: {
        'Accept': '*/*',
        'Accept-Encoding': 'gzip, deflate, br',
        'Authorization': 'Bearer ' + localStorage.getItem('access_token')
      }
    });
  }

  Post(url: string, body: any): Observable<any> {
    return this.http.post(url, body, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json; charset=UTF-8',
        'Authorization': 'Bearer ' + localStorage.getItem('access_token')
      }
    });
  }

  handleError(error: Error) {
    return Observable.throw(error);

  }

  Put(url: string, body: any) {
    return this.http.put(url, body, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json; charset=UTF-8',
        'Authorization': 'Bearer ' + localStorage.getItem('access_token')
      }
    });
  }

  PutWithFile(url: string, body: any): Observable<any> {
    return this.http.put(url, body, {
      headers: {
        'Accept': '*/*',
        'Accept-Encoding': 'gzip, deflate, br',
        'Authorization': 'Bearer ' + localStorage.getItem('access_token')
      }
    });
  }

  Delete(url: string) {
    return this.http.delete(url, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json; charset=UTF-8',
        'Authorization': 'Bearer ' + localStorage.getItem('access_token')
      }
    });
  }
  LogicalDelete(url: string) {
    return this.http.post(url, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json; charset=UTF-8',
        'Authorization': 'Bearer ' + localStorage.getItem('access_token')
      }
    });
  }
  LogicalDeleteRevoke(url: string) {
    return this.http.delete(url, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json; charset=UTF-8',
        'Authorization': 'Bearer ' + localStorage.getItem('access_token')
      }
    });
  }
  ExportProduct(url: string) {
    return this.http.post(url, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json; charset=UTF-8',
        'Authorization': 'Bearer ' + localStorage.getItem('access_token')
      }
    });
  }

  Patch(url: string, body: any) {
    return this.http.patch(url, body, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json; charset=UTF-8',
        'Authorization': 'Bearer ' + localStorage.getItem('access_token')
      }
    });
  }

  GetText(url: string): Observable<any> {
    return this.http.get(url, {
      headers: {},
      responseType: 'text'
    });
  }

  GetLatLong(url: string): Observable<any> {
    return this.http.get(url, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json; charset=UTF-8',
        'Org': 'connecipment.uat',
        'Authorization': 'Bearer ' + localStorage.getItem('access_token')
      }
    });
  }

  private GetUrlWithId(url: string, id: string): string {
    return url + "/?id=" + id;
  }

  private ExtractJSONResult(response): any {
    return response.json();
  }

  private GetHttpParams(obj: Object): HttpParams {
    let params = new HttpParams();
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const val = obj[key];
        if (val !== null && val !== undefined) {
          if (key == "searchModels")
            params = params.append(key, JSON.stringify(val));
          else
            params = params.append(key, val.toString());
        }
      }
    }
    return params;
  }
}
