export class DynamicPageContent {
  id: number;
  propertyFor:number;
  propertyTypeId: number;
  propertySubTypeId: number;
  kindOfPropertyId: number;
  cityId:number;
  title:string;
  metaDescription:string;
  metaKeyword:string;
  createdBy:string;
  createdOn:Date;
  updatedBy:string;
  updatedOn:Date;
  isDeleted:boolean;
  dynamicPageContentHeading: DynamicPageContentHeading[];
}


export class DynamicPageContentHeading {
  id: number;
  dynamicPageContentId: number;
  heading: string;
  description: string;
}
