import { Injectable, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { SearchOperators, PagingButtonUrl, SortingTypes, PagingButton } from '../model/common.model';
import { Router } from '@angular/router';
import { LocationStrategy } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpClient } from '@angular/common/http';


@Injectable()
export class CommonFunctions {
  constructor(private http: HttpClient , private _router: Router, private spinner: NgxSpinnerService, private location: LocationStrategy, private sanitizer: DomSanitizer) { }
  validationMessage = "";
  generalErrorMessage = "Something went wrong, please try again";
  dataNotFoundMessage = "No data found";
  months: string[] = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'Delete'];
  pagingButtonUrl: PagingButtonUrl;

  securePassPhrase: any;
  securePBKDF2Key: any;
  encryptionSalt: any;
  keySize: number = 512;
  iteration: number = 100;
  queryParameters: any;

  generateSecurePassPhrase() {
    //this.securePassPhrase = CryptoJS.lib.WordArray.random(128 / 8).toString(CryptoJS.enc.Base64);
  }

  getPBKDF2Key() {
    // this.securePBKDF2Key = CryptoJS.PBKDF2(this.securePassPhrase, this.encryptionSalt, {
    //   keySize: this.keySize / 4,
    //   iterations: this.iteration
    // }).toString(CryptoJS.enc.Base64);
  }

  getEncryptionSalt() {
    //this.encryptionSalt = CryptoJS.lib.WordArray.random(128 / 8).toString(CryptoJS.enc.Base64);
  }

  validateMail(mailString, modelName): boolean {
    if (mailString == null || mailString == "")
      return true;

    this.validationMessage = "";
    var re = /^([\w+-.%']+@[\w.-]+\.[A-Za-z]{2,4})$/;
    if (!re.test(String(mailString).toLowerCase())) {
      this.validationMessage = "Invalid " + modelName.toLowerCase() + " email";
      return false;
    }

    return true;
  }

  validateMutipleEmails(mailString, fieldName): boolean {
    if (mailString == null || mailString == "")
      return true;

    this.validationMessage = "";
    var re = /^([\w+-.%']+@[\w.-]+\.[A-Za-z]{2,4})(;\s+[\w+-.%']+@[\w.-]+\.[A-Za-z]{2,4})*$/;
    if (!re.test(String(mailString).toLowerCase())) {
      this.validationMessage = "Invalid " + fieldName;
      return false;
    }

    return true;
  }

  validateDomainName(domainString, modelName): boolean {
    if (domainString == null || domainString == "")
      return true;

    this.validationMessage = "";
    var re = /^((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!re.test(String(domainString).toLowerCase())) {
      this.validationMessage = "Invalid " + modelName.toLowerCase() + " domain Name";
      return false;
    }

    return true;
  }

  validateRequired(value, propertyName): boolean {
    this.validationMessage = "";

    if (value === null || value === "" || value === undefined) {
      this.validationMessage = "'" + propertyName + "' required";
      return false;
    }

    return true;
  }

  validateSerialNo(value, propertyName): boolean {
    if (value == null || value == "")
      return true;

    this.validationMessage = "";
    var re = /^(9-\d{5})$/;
    if (!re.test(value)) {
      this.validationMessage = "'" + propertyName + "' should be of format: 9-xxxxx";
      return false;
    }

    return true;
  }

  validateRequiredDdl(value, propertyName): boolean {
    this.validationMessage = "";

    if (value == '0') {
      this.validationMessage = "'" + propertyName + "' required";
      return false;
    }

    return true;
  }

  validateRequiredModel(value, propertyName): boolean {
    this.validationMessage = "";
    if (value == '0' && propertyName == 'ModelID') {
      this.validationMessage = "Please select a valid Model";
      return false;
    }
    if (value == '-1' && propertyName == 'Model') {
      this.validationMessage = "Please select a valid Model";
      return false;
    }

    return true;
  }

  validateBaaNInformation(value) {
    if (value > 999999) {
      this.validationMessage = "'BaaN Information' is Invalid.";
      return false;
    }
    return true;
  }

  validateSoftwareVersion(value, versionId) {
    if (versionId === 0 && value === "000000000000") {
      this.validationMessage = "Software Version Information '00.00.0000.0000' is Invalid";
      return false;
    }
    else if (versionId === 0 && value.length !== 12) {
      this.validationMessage = "Software Version is Invalid. Software Version should match 'NN.NN.NNNN.NNNN' pattern";
      return false;
    }
    return true;
  }

  validateNumeric($event): boolean {
    let pattern = /^([0-9])$/;
    var data: any;
    if ($event.type === "paste") {
      data = $event.clipboardData.getData('text/plain');
    }
    else if ($event.type === "keypress") {
      data = $event.key;
    }
    if (data != null) {
      let result = pattern.test(data);
      return result;
    }
  }

  validateMobile(data): boolean {
    let pattern = /^((\\+91-?)|0)?[0-9]{10}$/;

    if (data != null) {
      let result = pattern.test(data);
      return result;
    }
  }

  validateNumericWithSpecialCharacters($event, specialChar): boolean {
    var pattern = new RegExp("^([0-9" + specialChar + "])$");
    var data: any;
    if ($event.type === "paste") {
      data = $event.clipboardData.getData('text/plain');
    }
    else if ($event.type === "keypress") {
      data = $event.key;
    }
    if (data != null) {
      let result = pattern.test(data);

      if (result && $event.key == "-" && $event.target.value.length > 0)
        return false;

      return result;
    }
  }

  validateAlphaNumeric($event, specialChar): boolean {
    var pattern = new RegExp("^([a-zA-Z0-9" + specialChar + "]*)$");
    var data: any;
    if ($event.type === "paste") {
      data = $event.clipboardData.getData('text/plain');
    }
    else if ($event.type === "keypress") {
      data = $event.key;
    }
    if (data != null) {
      let result = pattern.test(data);
      return result;
    }
  }

  getPropertyName(obj, value) {
    var res = '';
    for (var i in obj) {
      if (typeof obj[i] == 'object') {
        if (this.getPropertyName(obj[i], value)) {
          return res;
        }
      } else {
        if (obj[i] == value) {
          res = i;
          return res;
        }
      }
    }
    return undefined;
  }

  isValueExist(value) {
    if (value != null && value != "")
      return true;

    return false;
  }

  getOperator(value) {
    if (value != null && value.trim() != "" && value.includes('*'))
      return SearchOperators.Like;
    else if (value != null && value.trim() != "" && value.includes(','))
      return SearchOperators.In;
    else
      return SearchOperators.Equal;
  }

  getClientTimeStamp(value) {
    var date = new Date(value);
    var timeZoneOffset = new Date().getTimezoneOffset();
    var milliseconds = Date.parse(date.toString());
    milliseconds = milliseconds - (timeZoneOffset * 60 * 1000);
    return new Date(milliseconds);
  }

  getDateString(dateString: string) {
    return dateString.replace(/(^|\D)(\d)(?!\d)/g, '$10$2');
  }

  preventBackButton() {
    history.pushState(null, null, window.location.href);
    this.location.onPopState(() => {
      history.pushState(null, null, window.location.href);
    });
  }

  showError(toaster, error) {
    if (error == null)
      return;

    var errorText = "";

    this.spinner.hide();

    if (error.status === 401) {
      toaster.error("Session Expired. Please login again.");
      this.clearLocalStorage();
      this._router.navigateByUrl('/logout', { skipLocationChange: true }).then(() => {
        
      });
      return;
    }

    if (error.status === 403) {
      toaster.error("You are not authorized to view this page.");
      this.clearLocalStorage();
      this._router.navigate(['/unauthorized']);
      return;
    }

    if (error.status === 0) {
      toaster.error("API is not responding. Please report to support team.");
      return;
    }

    if (error.status == 400 || error.status == 404) {
      if (error.error.errors && error.error.errors.description == undefined) {
        for (var x in error.error.errors) {
          if (error.error.errors[x].description != undefined)
            errorText += error.error.errors[x].description + " ";
          else
            errorText += error.error.errors[x] + " ";
        }
      }
      else if (error.error && error.error.description == undefined) {
        for (var x in error.error) {
          if (error.error[x].description != undefined)
            errorText += error.error[x].description + " ";
          else
            errorText += error.error[x] + " ";
        }
      }
      else
        errorText = error.error.description;

      toaster.error(errorText);

      return;
    }

    if ((error.error != null && (error.error.length == 0 || error.error.length == undefined)) && error.status != 500) {
      for (var x in error.error.errors) {
        errorText += error.error.errors[x] + " ";
      }

      toaster.error(errorText);
      return;
    }

    if (error.error != null && error.error != "" && error.statusText != "Unknown Error") {

      if (error.status == 500) {
        toaster.error(this.generalErrorMessage);
      } else {
        for (var i = 0; i < error.error.length; i++) {
          if (i == 0)
            errorText = errorText + error.error[i].description
          else
            errorText = errorText + ", " + error.error[i].description
        }
        toaster.error(errorText).message;
      }
    } else {
      if (error.status == 0) {
        toaster.error("Server is down...");
      } else {
        toaster.error("Some error occured");
      }
    }
  }

  getDateInUTC(date: Date) {
    const now = new Date(date);
    return new Date(now.getTime() + (now.getTimezoneOffset() * 60000));
  }

  parseDate(dateString: string): Date {
    if (dateString) {
      return new Date(dateString);
    }
    return null;
  }

  getTwelveHourTimeClock(timeString: string) {
    if (timeString == "NA")
      return "NA";
    else {
      var H = +timeString.substr(0, 2);
      var h = H % 12 || 12;
      var hour = h < 11 ? '0' + h : h.toString();
      var ampm = (H < 12 || H === 24) ? "AM" : "PM";
      timeString = hour + timeString.substr(2, 3) + " " + ampm;
      return timeString;
    }
  }

  validateNumericMinMax($event) {
    if (this.specialKeys.indexOf($event.key) !== -1)
      return;

    let isnumeric = this.validateNumeric($event);
    if (!isnumeric) {
      if ($event.key == "-" && $event.target.value == "")
        return true;
      else
        return false;
    }

    let value = Number.parseInt($event.target.value + $event.key);
    var min = Number.parseInt($event.target.attributes.min.value);
    var max = Number.parseInt($event.target.attributes.max.value);

    if (value < min || value > max)
      return false;

    return true;
  }

  createAdditionalFieldTypes(): any {
    //var fieldTypeOptions = Object.keys(AdditionalFieldTypes);
    //return fieldTypeOptions.slice(fieldTypeOptions.length / 2);
  }

  getParsedEnum<T>(inputEnum: T): any {
    var enumOptions = Object.keys(inputEnum);
    return enumOptions.slice(enumOptions.length / 2);
  }

  initializePagingButtonURL(operationInfo, recordCount) {
    this.pagingButtonUrl = new PagingButtonUrl();

    if (recordCount > 0) {
      if (operationInfo.pageIndex == 1) {
        this.pagingButtonUrl.first = "../../../../assets/images/icon-first-button-gray-32.png";
        this.pagingButtonUrl.previous = "../../../../assets/images/icon-prev-button-gray-32.png";
        this.pagingButtonUrl.isPreviousDisabled = true;
      }
      else if (operationInfo.pageIndex > 1) {
        this.pagingButtonUrl.first = "../../../../assets/images/icon-first-button-32.png";
        this.pagingButtonUrl.previous = "../../../../assets/images/icon-prev-button-32.png";
        this.pagingButtonUrl.isPreviousDisabled = false;
      }

      let balanceRecordCount = Number(recordCount - (Number(operationInfo.pageIndex) * operationInfo.pageSize));
      if (Number(balanceRecordCount) <= 0) {
        this.pagingButtonUrl.next = "../../../../assets/images/icon-next-button-gray-32.png";
        this.pagingButtonUrl.last = "../../../../assets/images/icon-last-button-gray-32.png";
        this.pagingButtonUrl.isNextDisabled = true;
        this.pagingButtonUrl.pageIndexText = "" + (((operationInfo.pageIndex - 1) * operationInfo.pageSize) + 1) + " - " + recordCount + " of " + recordCount;
      } else {
        this.pagingButtonUrl.next = "../../../../assets/images/icon-next-button-32.png";
        this.pagingButtonUrl.last = "../../../../assets/images/icon-last-button-32.png";
        this.pagingButtonUrl.isNextDisabled = false;
        this.pagingButtonUrl.pageIndexText = "" + (((operationInfo.pageIndex - 1) * operationInfo.pageSize) + 1) + " - " +
          (((operationInfo.pageIndex - 1) * operationInfo.pageSize) + operationInfo.pageSize) + " of " + recordCount;
      }
    }
    else {
      this.pagingButtonUrl.first = "../../../../assets/images/icon-first-button-gray-32.png";
      this.pagingButtonUrl.previous = "../../../../assets/images/icon-prev-button-gray-32.png";
      this.pagingButtonUrl.next = "../../../../assets/images/icon-next-button-gray-32.png";
      this.pagingButtonUrl.last = "../../../../assets/images/icon-last-button-gray-32.png";
    }
    return this.pagingButtonUrl;
  }

  setSortingProperty(operationInfo, sortingTypes, event) {
    if (event.direction != null && event.direction != "" && event.direction != "none") {
      if (event.direction == "desc")
        operationInfo.sortingType = sortingTypes[SortingTypes.Desc];
      else
        operationInfo.sortingType = sortingTypes[SortingTypes.Asc];

      operationInfo.sortingColumn = event.active;
    } else {
      operationInfo.sortingType = sortingTypes[SortingTypes.Asc];
      operationInfo.sortingColumn = "Name";
    }

    return operationInfo;
  }

  setPageIndex(operationInfo, recordCount, event) {
    switch (event) {
      case PagingButton.first:
        {
          operationInfo.pageIndex = 1;
        }
        break
      case PagingButton.next:
        {
          let balanceRecordCount = Number(recordCount - (Number(operationInfo.pageIndex) * operationInfo.pageSize));
          if (Number(balanceRecordCount) > 0) {
            operationInfo.pageIndex = operationInfo.pageIndex + 1;
          }
        }
        break
      case PagingButton.previous:
        {
          if (operationInfo.pageIndex != 1)
            operationInfo.pageIndex = operationInfo.pageIndex - 1;
        }
        break
      case PagingButton.last:
        {
          let newPageIndex = operationInfo.pageIndex = Number((recordCount / operationInfo.pageSize).toFixed());
          let balanceRecordCount = Number(recordCount - (Number(newPageIndex) * operationInfo.pageSize));
          if (Number(balanceRecordCount) > 0) {
            operationInfo.pageIndex = Number((recordCount / operationInfo.pageSize).toFixed()) + 1;
          } else {
            operationInfo.pageIndex = Number((recordCount / operationInfo.pageSize).toFixed());
          }
        }
        break
    }

    return operationInfo.pageIndex;
  }

  isHexadecimal($event) {
    let pattern = /^[0-9a-fA-F]+$/;

    if ($event.type === "paste")
      return pattern.test($event.clipboardData.getData('text/plain'));

    return pattern.test($event.key);
  }

  isHexadecimalValue(val) {
    let pattern = /^[0-9a-fA-F]+$/;

    return pattern.test(val);
  }

  checkNullOrEmpty(value) {
    if (value == undefined || value == null || value == "") {
      return true;
    }
    return false;
  }

  isValidDate(value, userFormat) {

    // Set default format if format is not provided
    userFormat = userFormat || 'yyyy-mm-dd';

    // Find custom delimiter by excluding the
    // month, day and year characters
    var delimiter = /[^mdy]/.exec(userFormat)[0];

    // Create an array with month, day and year
    // so we know the format by index
    var theFormat = userFormat.split(delimiter);

    // Get the user date now that we know the delimiter
    var theDate = value.split(delimiter);

    function isDate(date, format) {
      var m, d, y, i = 0, len = format.length, f;
      for (i; i < len; i++) {
        f = format[i];
        if (/m/.test(f)) m = date[i];
        if (/d/.test(f)) d = date[i];
        if (/y/.test(f)) y = date[i];
      }

      return (
        m > 0 && m < 13 && y.length === 4 && y > 1752 && y < 2100 && d > 0 &&    // Is it a valid day of the month?
        d <= (new Date(y, m, 0)).getDate()
      );
    }

    return isDate(theDate, theFormat);

  }

  // Turn enum into array
  ToArray(enumme) {
    return Object.keys(enumme)
      .map(key => enumme[key]);
  }

  pad(num, size) {
    num = num.toString();
    while (num.length < size) num = "0" + num;
    return num;
  }

  clearLocalStorage() {
    localStorage.setItem('access_token', null);
    localStorage.setItem('userToken', null);
    localStorage.setItem('userEmail', null);
    localStorage.setItem('userId', null);
    localStorage.setItem('userMobile', null);
    localStorage.setItem('userName', null);
    localStorage.setItem('isAuthenticated', 'false');
    localStorage.setItem('userType', null);
  }

  // testApi() {
  //   return this.http.get('https://warehouse.tulaa.ca/weatherforecast');
  // }
  isAuthenticated() {
    return localStorage.getItem('isAuthenticated') == "true" ? true : false;
  }

  replaceSpace(str) {
    str = str.replace('-', '_').toLowerCase();
    str = str.replace('/', '_');
    str = str.replace(/^\s+|\s+$/gm, '');
    return str.replace(/\s+/g, '-');
  }

  replaceCity(cityString) {
    cityString = cityString.replace('gurgoan', 'gurugram');
    cityString = cityString.replace('bangalore', 'bengaluru');
    return cityString;
  }
}
