import { Component, OnInit, Input } from '@angular/core';
import { SignUpModel, SendSignUpEmailModel } from '../../model/signup.model';
import { CommonFunctions } from '../../common/CommonFunction';
import { ToastrService } from 'ngx-toastr';
import { SignUpService } from '../../services/signup.service';
import { Router } from '@angular/router';
import { PostPropertyComponent } from '../post-property/post-property.component';
import { DialogService } from '../../services/dialog.service';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.less'],
})



export class SignupComponent implements OnInit {

  @Input() data: PostPropertyComponent;

  signup: SignUpModel;
  sendSignUpEmailModel:SendSignUpEmailModel;
  confirmPassword: string;
  isViewOnly: boolean = false;
  constructor(private _router: Router, private spinner: NgxSpinnerService, private _dialogService: DialogService, private _signUpService: SignUpService, private _toaster: ToastrService, public _commonFunctions: CommonFunctions) { }

  ngOnInit(): void {
    this.signup = new SignUpModel();
    this.sendSignUpEmailModel = new SendSignUpEmailModel();
    this.signup.isOwner = 0;
    this.confirmPassword = "";
  }

  signUpNewUser() {

    if (!this._commonFunctions.validateRequired(this.signup.firstName, "First Name")) {
      this._toaster.error(this._commonFunctions.validationMessage);
      return false;
    } else if (!this._commonFunctions.validateRequired(this.signup.lastName, "Last Name")) {
      this._toaster.error(this._commonFunctions.validationMessage);
      return false;
    } else if (!this._commonFunctions.validateRequired(this.signup.email, "Email")) {
      this._toaster.error(this._commonFunctions.validationMessage);
      return false;
    } else if (!this._commonFunctions.validateMail(this.signup.email, "Email")) {
      this._toaster.error(this._commonFunctions.validationMessage);
      return false;
    } else if (!this._commonFunctions.validateRequired(this.signup.mobileNumber, "Mobile Number")) {
      this._toaster.error(this._commonFunctions.validationMessage);
      return false;
    } else if (!this._commonFunctions.validateMobile(this.signup.mobileNumber)) {
      this._toaster.error('Invalid Mobile Number');
      return false;
    } else if (this.signup.isOwner == null || !this._commonFunctions.validateRequiredDdl(this.signup.isOwner, "Owner/Broker")) {
      this._toaster.error("Owner/Broker required");
      return false;
    } else if (!this._commonFunctions.validateRequired(this.signup.password, "Password")) {
      this._toaster.error(this._commonFunctions.validationMessage);
      return false;
    } else if (!this._commonFunctions.validateRequired(this.confirmPassword, "Confirm Password")) {
      this._toaster.error(this._commonFunctions.validationMessage);
      return false;
    } else if (this.signup.password != this.confirmPassword) {
      this._toaster.error('Password and Confirm password does not match');
      return false;
    }

   // this.spinner.show();
    this.signup.isOwner = Number(this.signup.isOwner);
    this.sendSignUpEmailModel = new SendSignUpEmailModel();
    this.sendSignUpEmailModel.Email = this.signup.email;
    this.sendSignUpEmailModel.FirstName = this.signup.firstName;

    this._signUpService.signUpNewUser(this.signup).subscribe(
      data => {
        this.login();
        
        this.spinner.hide();
        this._signUpService.sendSignUpEmail(this.sendSignUpEmailModel).subscribe(
          data => {
          },
          error => {
           
          }
        );
        this.clearSignUpForm();
      },
      error => {
        this.spinner.hide();
        this._commonFunctions.showError(this._toaster, error);
      }
    );

  }

  clearSignUpForm() {
    this.signup = new SignUpModel();
    this.confirmPassword = "";
    this.signup.isOwner = 0;
  }


  login() {
    this.spinner.show();
    this._signUpService.GetToken(this.signup).subscribe(
      data => {
        this.spinner.hide();
        localStorage.setItem('access_token', data.accessToken);
        localStorage.setItem('userToken', data.accessToken);
        localStorage.setItem('userEmail', data.emailAddress);
        localStorage.setItem('userMobile', data.mobile);
        localStorage.setItem('userName', data.userName);
        localStorage.setItem('userId', data.userId);
        localStorage.setItem('userProfileImage', data.profilePicture);
        localStorage.setItem('userType', data.userTypeId);
        localStorage.setItem('isAuthenticated', 'true');

        var currentURL = this._router.url;
        this._router.navigateByUrl('/post-property')
          .then(() => {
            if (currentURL != '/post-property')
              window.location.reload();
            else {
              this._dialogService.onChangePostProperty.emit({
                data: "Post_Form_Data"
              })
            }
          });
      },
      error => {
        this.spinner.hide();
        this._commonFunctions.showError(this._toaster, error);
      });
  }
}
